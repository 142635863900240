import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { get } from '../redux/actions/ordenTrabajoActions';
import { get as getActividad } from '../redux/actions/actividadActions';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';

import { callStatus } from '../utils/constants';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    flexGrow: 1,
  },
  text: {
    marginBottom: 3,
    fontSize: 14,
  },
  contacto: {
    marginBottom: 2,
    fontSize: 12,
  },
  tableHeader: {
    marginBottom: 6,
    fontSize: 16,
    borderBottom: 1,
  },
  tableData: {
    borderBottom: 1,
    paddingBottom: 3,
    paddingTop: 3,
    fontSize: 14,
  },
  firma: {
    border: 1,
    height: '100px',
    flex: 1,
    marginLeft: '10px',
    marginTop: '15px',
  },
});

// Create Document Component
const MyDocument = ({ orden, actividad }) => (
  <Document>
    <Page size="A4">
      <View style={{ margin: 15 }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Image
            style={{
              width: '200px',
            }}
            src="logo.png"
          />
          <View
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <Text style={styles.contacto}>
              9 de Julio 382 - Trenque Lauquen
            </Text>
            <Text style={styles.contacto}>Tel. 02392 - 15631402</Text>
            <Text style={styles.contacto}>agrolalluvia@gmail.com</Text>
          </View>
          <View
            style={{
              flexDirection: 'column',
              marginRight: '10px',
            }}
          >
            <Text style={styles.text}>Orden de Trabajo Agrícola</Text>
            <Text style={styles.text}>Nº: {orden.numero}</Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.section}>
            <Text style={styles.text}>Fecha: {orden.fecha}</Text>
            <Text style={styles.text}>
              Establecimiento: {actividad.establecimiento.nombre}
            </Text>
            <Text style={styles.text}>Cultivo: {actividad.cultivo.nombre}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>Proveedor: {orden.proveedor.nombre}</Text>
            <Text style={styles.text}>Superficie: {orden.superficie} </Text>
            <Text style={styles.text}>Labor: {orden.labor.nombre}</Text>
          </View>
        </View>

        <View style={{ flexDirection: 'row' }}>
          <View style={styles.section}>
            <Text style={styles.text}>Observación: {orden.observacion}</Text>
          </View>
        </View>
        <View style={{ ...styles.section, flexDirection: 'row' }}>
          <View style={{ flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ ...styles.tableHeader, width: '200px' }}>
                Insumo
              </Text>
              <Text style={{ ...styles.tableHeader, width: '100px' }}>
                Dosis
              </Text>
              <Text style={{ ...styles.tableHeader, width: '100px' }}>
                Total
              </Text>
            </View>
            {orden.insumos.map((item, index) => (
              <View style={{ flexDirection: 'row' }} key={index}>
                <Text style={{ ...styles.tableData, width: '200px' }}>
                  {item.insumo.nombre}
                </Text>
                <Text style={{ ...styles.tableData, width: '100px' }}>
                  {item.dosis}
                </Text>
                <Text style={{ ...styles.tableData, width: '100px' }}>
                  {item.total}
                </Text>
              </View>
            ))}
          </View>
          <View style={styles.firma}>
            <View
              style={{
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Text style={styles.text}>_____________</Text>
              <Text style={{ fontSize: 11, marginBottom: 5 }}>Firma</Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

const OrderTrabajoViewer = ({
  dispatch,
  stateData,
  actividad,
  id,
  visible,
  onCancel,
}) => {
  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      dispatch(getActividad(stateData.object.data.actividad_id));
    }
    // eslint-disable-next-line
  }, [stateData]);

  return (
    <DraggableModalProvider>
      <DraggableModal
        mask
        initialWidth={700}
        initialHeight={700}
        maskClosable={false}
        visible={visible}
        title="Impirmir Orden de Trabajo"
        onCancel={onCancel}
        bodyStyle={{ padding: 0, background: '#3a3d3f ' }}
        footer={null}
      >
        {stateData.object.status === callStatus.OBJECT_SUCCESS &&
          actividad.object.status === callStatus.OBJECT_SUCCESS && (
            <PDFViewer
              style={{
                width: '100%',
                height: '700px',
                border: 'none',
                margin: 0,
                padding: 0,
                marginTop: '-2px',
              }}
            >
              <MyDocument
                orden={stateData.object.data}
                actividad={actividad.object.data}
              />
            </PDFViewer>
          )}
      </DraggableModal>
    </DraggableModalProvider>
  );
};

const mapStateToProps = (state) => {
  const { ordenTrabajo, actividad } = state;
  const stateData = ordenTrabajo;
  return {
    stateData,
    actividad,
  };
};
export default connect(mapStateToProps)(OrderTrabajoViewer);
