import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Row, Col, DatePicker, Spin, Select } from 'antd';
import { get, save } from '../redux/actions/produccionRetiroActions';

import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
import { callStatus } from '../utils/constants';
import { Notificacion } from './Notificacion';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
const { confirm } = Modal;

const ProduccionRetiroEditor = ({
  dispatch,
  id,
  parentId,
  stateData,
  visible,
  onCancel,
  destino,
  inversor,
}) => {
  const [form] = Form.useForm();

  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!id) {
      let data = {};
      data['fecha'] = moment(moment(data.fecha));

      form.setFieldsValue(data);
      setInitial(data);
    }

    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      let data = cloneDeep(stateData.object.data);
      data['fecha'] = moment(moment(data.fecha));

      form.setFieldsValue(data);
      setInitial(data);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      Notificacion('El registro se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      Notificacion(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);
    data.actividad_id = parentId;

    dispatch(save(data)).then((response) => {
      onCancel();
    });
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  const calcImporte = () => {
    const cantidad = form.getFieldValue('cantidad');
    const precio = form.getFieldValue('precio_unitario');
    const dolar = form.getFieldValue('dolar');

    if (cantidad && precio && dolar) {
      const importe = (cantidad * precio) / dolar;
      form.setFieldsValue({ importe: importe.toFixed(2) });
    }
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          mask
          initialWidth={700}
          initialHeight={700}
          maskClosable={false}
          visible={visible}
          title={stateData.object.data.id ? 'Editar Retiro' : 'Nuevo Retiro'}
          okText="Guardar"
          cancelText="Salir"
          onCancel={handleOnCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Spin
            spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={stateData.object.data}
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="id" label="id" style={{ display: 'none' }}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item
                    name="fecha"
                    label="Fecha"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <DatePicker
                      format={formatDisplay}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                  <Form.Item
                    name="camionero"
                    label="Camionero"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="cantidad"
                    label="Cantidad"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      addonBefore="Tn"
                      onBlur={calcImporte}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="precio_unitario" label="Precio por Cantidad">
                    <Input type="number" addonBefore="$" onBlur={calcImporte} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="dolar" label="Dolar">
                    <Input
                      type="number"
                      addonBefore="U$D"
                      onBlur={calcImporte}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="importe" label="Importe">
                    <Input type="number" addonBefore="U$D" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="destino_id" label="Destino">
                    <Select allowClear>
                      {destino.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="inversor_id" label="Inversor">
                    <Select allowClear>
                      {inversor.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </DraggableModal>
      </DraggableModalProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  const { produccionRetiro, destino, inversor } = state;
  const stateData = produccionRetiro;
  return {
    stateData,
    destino,
    inversor,
  };
};
export default connect(mapStateToProps)(ProduccionRetiroEditor);
