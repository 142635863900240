import React, { useState, useEffect } from 'react';
import AlquilerEditor from './AlquilerEditor';
import { connect } from 'react-redux';
import {
  // getAll,
  setPending,
  deleted,
  setDeletedPending,
  getAllDeleted,
  restore,
  getAllByParent,
} from '../redux/actions/alquilerActions';
import { get as getParent } from '../redux/actions/campanaActions';

import { callStatus } from '../utils/constants';
import { Button, Layout, Modal, Space, Tag } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  PlusCircleOutlined,
  SelectOutlined,
  ExclamationCircleOutlined,
  EditFilled,
  DeleteFilled,
} from '@ant-design/icons';
import styled from 'styled-components';
import RestorePopup from './RestorePopup';
import MetaTable from './base/MetaTable';
import AlquilerPropietarioList from './AlquilerPropietarioList';
const { confirm } = Modal;

const { Content, Header, Footer } = Layout;

const SelectedContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

const AlquilerList = ({
  location,
  dispatch,
  stateData,
  match: {
    params: { id },
  },
  parent,
}) => {
  const [showRestore, setShowRestore] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [rowEdit, setRowEdit] = useState(null);

  useEffect(() => {
    dispatch(getAllByParent(id));
    dispatch(getParent(id));
    dispatch(setPending());
    // eslint-disable-next-line
  }, []);

  const handleEdit = (data) => {
    dispatch(setPending());
    setRowEdit(data);
    setShowEdit(true);
  };

  const handleRestore = () => {
    setShowRestore(true);
    getAllDeleted(dispatch);
  };

  const handleRestoreCancel = () => {
    setShowRestore(false);
    dispatch(setDeletedPending());
  };

  const handleRestoreOk = (data) => {
    dispatch(restore({ data: data }));
    setShowRestore(false);
  };

  const handleDelete = (data) => {
    dispatch(deleted(data.id));
  };

  const handleConfirm = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  const handleHideEditor = () => {
    setShowEdit(false);
    setRowEdit(null);
    dispatch(setPending());
  };

  const columns = [
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={(e) => handleEdit(record)} />
          <DeleteFilled onClick={(e) => handleConfirm(record)} />
        </Space>
      ),
      width: 60,
      ellipsis: true,
    },
    {
      width: 120,
      ellipsis: true,
      title: 'Número',
      dataIndex: 'numero',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Superficie',
      dataIndex: 'superficie',
    },

    {
      title: 'Propietarios',
      dataIndex: 'alquiler_propietarios',
      render: (_, { alquiler_propietarios }) => (
        <>
          {alquiler_propietarios.map((tag, index) => {
            return (
              <Tag color="green" key={index}>
                {tag.propietario?.nombre}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Actividades',
      dataIndex: 'alquiler_actividades',
      render: (_, { alquiler_actividades }) => (
        <>
          {alquiler_actividades.map((tag, index) => {
            return (
              <Tag color="geekblue" key={index}>
                {tag.actividad?.establecimiento.nombre} /{' '}
                {tag.actividad?.cultivo.nombre}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];

  const restoreColumns = [
    {
      ellipsis: true,
      title: 'Nombre',
      dataIndex: 'nombre',
    },
  ];

  const handleExpandable = (value, search) => {
    return <AlquilerPropietarioList parentID={value.id} parentID2={id} />;
  };

  return (
    <>
      <Layout style={{ height: '100%' }}>
        <Header
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: '5px',
          }}
        >
          {parent.object.status === callStatus.OBJECT_SUCCESS && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <div
                style={{
                  height: '17px',
                }}
              >
                <b>Campaña: </b>
                {parent.object.data.nombre}
              </div>
            </div>
          )}
        </Header>
        <Content>
          <MetaTable
            dataSource={stateData.list.data}
            dataColumns={columns}
            bordered={false}
            selection={false}
            loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
            expandable={handleExpandable}
          />
        </Content>
        <Footer>
          <FooterContainer>
            <SelectedContainer></SelectedContainer>
            <ActionsContainer>
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                size="small"
                onClick={(e) => handleEdit({})}
              >
                Agregar
              </Button>
              <Button
                type="primary"
                icon={<SelectOutlined />}
                size="small"
                style={{ marginLeft: '3px' }}
                onClick={(e) => handleRestore()}
              >
                Recuperar
              </Button>
            </ActionsContainer>
          </FooterContainer>
        </Footer>

        {showRestore &&
        stateData.deleted.status === callStatus.LIST_DELETED_SUCCESS ? (
          <RestorePopup
            columns={restoreColumns}
            data={stateData.deleted.data}
            onCancel={handleRestoreCancel}
            onOk={handleRestoreOk}
          />
        ) : (
          false
        )}
      </Layout>
      {showEdit ? (
        <AlquilerEditor
          id={rowEdit.id}
          parentId={id}
          onCancel={handleHideEditor}
          // onCreate={handleSaveEditor}
          visible={true}
        />
      ) : (
        false
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { alquiler, campana } = state;
  const stateData = alquiler;
  const parent = campana;
  return {
    stateData,
    parent,
  };
};
export default connect(mapStateToProps)(withRouter(AlquilerList));
