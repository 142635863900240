export const callStatus = {
  LIST_PENDING: 'LIST_PENDING',
  LIST_IN_PROCESS: 'LIST_IN_PROCESS',
  LIST_FAILED: 'LIST_FAILED',
  LIST_SUCCESS: 'LIST_SUCCESS',
  LIST_REMOVE: 'LIST_REMOVE',
  LIST_ADD: 'LIST_ADD',
  LIST_UPDATE: 'LIST_UPDATE',
  LIST_DELETED_PENDING: 'LIST_DELETED_PENDING',
  LIST_DELETED_IN_PROCESS: 'LIST_DELETED_IN_PROCESS',
  LIST_DELETED_FAILED: 'LIST_DELETED_FAILED',
  LIST_DELETED_SUCCESS: 'LIST_DELETED_SUCCESS',
  OBJECT_PENDING: 'OBJECT_PENDING',
  OBJECT_IN_PROCESS: 'OBJECT_IN_PROCESS',
  OBJECT_FAILED: 'OBJECT_FAILED',
  OBJECT_SUCCESS: 'OBJECT_SUCCESS',
  PENDING: 'PENDING',
  IN_PROCESS: 'IN_PROCESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  OBJECT_SAVE_SUCCESS: 'OBJECT_SAVE_SUCCESS',
};
