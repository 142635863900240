import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { callStatus } from '../utils/constants';

import { Layout, Typography, Divider } from 'antd';
import { withRouter } from 'react-router-dom';
import { campanaApi } from '../utils/api';

import MetaTable from './base/MetaTable';
import { get } from '../redux/actions/campanaActions';

const { Content, Header } = Layout;

const CampanaResumenInversion = ({
  location,
  dispatch,
  stateData,
  cultivo,
  inversor,
  match: {
    params: { id },
  },
}) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    rows: { 0: [] },
    columns: {
      0: [
        {
          title: 'Establecimiento',
          dataIndex: 'establecimiento',
          key: 'establecimiento',
          width: 200,
        },
        {
          title: 'Total',
          dataIndex: 'total',
          key: 'total',
          width: 200,
        },
      ],
    },
  });

  const getResumen = () => {
    setLoading(true);
    campanaApi
      .get(`/resumen-produccion-cultivo/${id}`)
      .then((response) => {
        let dataFinal = {};
        let columnsFinal = {};
        let totalFinalInv = {};
        let porcentajeFinalInv = {};

        response.data.forEach((cultivo, index) => {
          columnsFinal[cultivo.cultivo.nombre] = [
            {
              title: 'Establecimiento',
              dataIndex: 'establecimiento',
              key: 'establecimiento',
              width: 200,
            },
            ...inversor.list.data.map((inversor) => {
              return {
                title: inversor.nombre,
                dataIndex: inversor.id,
                key: inversor.id,
                width: 200,
              };
            }),
            {
              title: 'Total',
              dataIndex: 'total',
              key: 'total',
              width: 200,
            },
          ];

          let data = [];
          let totalInv = {};
          let totalInvFinal = 0;

          cultivo.actividades.forEach((actividad) => {
            let inv = {};
            let totalCultivo = 0;

            inversor.list.data.forEach((inversor) => {
              const findInversor = actividad.resumen.inversores.find(
                (inv) => inv.inversor_id === inversor.id
              );

              if (findInversor) {
                inv[inversor.id] = findInversor.total_invertido;

                totalInv[inversor.id] = totalInv[inversor.id]
                  ? parseFloat(
                      (totalInv[inversor.id] + inv[inversor.id]).toFixed(2)
                    )
                  : inv[inversor.id];
              } else {
                inv[inversor.id] = 0;
                totalInv[inversor.id] = totalInv[inversor.id]
                  ? parseFloat(
                      (totalInv[inversor.id] + inv[inversor.id]).toFixed(2)
                    )
                  : inv[inversor.id];
              }

              totalCultivo = totalCultivo + inv[inversor.id];
              totalInvFinal = totalInvFinal + inv[inversor.id];
            });

            data.push({
              ...inv,
              establecimiento: actividad.establecimiento.nombre,
              total: totalCultivo.toFixed(2),
            });
          });

          totalInv = {
            ...totalInv,
            establecimiento: 'TOTAL',
            total: totalInvFinal.toFixed(2),
          };

          let porcentajeInv = {};
          let totalPorcentajeInv = 0;
          inversor.list.data.forEach((inversor) => {
            porcentajeInv[inversor.id] = parseFloat(
              ((totalInv[inversor.id] * 100) / totalInv.total).toFixed(2)
            );
            totalPorcentajeInv =
              totalPorcentajeInv + porcentajeInv[inversor.id];
          });

          porcentajeInv = {
            ...porcentajeInv,
            establecimiento: '%',
            total: totalPorcentajeInv.toFixed(2),
          };

          dataFinal[cultivo.cultivo.nombre] = [
            ...data,
            { ...totalInv },
            { ...porcentajeInv },
          ];
        });

        Object.entries(dataFinal).forEach(([key, value]) => {
          Object.entries(value[value.length - 2]).forEach(([key, value]) => {
            if (key !== 'establecimiento') {
              totalFinalInv[key] = totalFinalInv[key]
                ? parseFloat(
                    (totalFinalInv[key] + parseFloat(value)).toFixed(2)
                  )
                : parseFloat(value);
            } else {
              totalFinalInv[key] = 'TOTAL FINAL';
            }
          });
        });

        Object.entries(totalFinalInv).forEach(([key, value]) => {
          if (key === 'total') {
            porcentajeFinalInv[key] = 100;
          } else if (key !== 'establecimiento') {
            porcentajeFinalInv[key] = parseFloat(
              ((value * 100) / totalFinalInv.total).toFixed(2)
            );
          } else {
            porcentajeFinalInv[key] = '% FINAL';
          }
        });

        dataFinal['TOTALES'] = [
          { ...totalFinalInv },
          { ...porcentajeFinalInv },
        ];

        columnsFinal['TOTALES'] = [
          {
            title: '',
            dataIndex: 'establecimiento',
            key: 'establecimiento',
            width: 200,
          },
          ...inversor.list.data.map((inversor) => {
            return {
              title: inversor.nombre,
              dataIndex: inversor.id,
              key: inversor.id,
              width: 200,
            };
          }),
          {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            width: 200,
          },
        ];

        setLoading(false);
        setData({ rows: dataFinal, columns: columnsFinal });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(get(id));
    getResumen();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout style={{ height: '100%' }}>
      <Header
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          marginTop: '5px',
          // marginBottom: '10px',
          // height: '50px',
        }}
      >
        {stateData.object.status === callStatus.OBJECT_SUCCESS && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <div
              style={{
                height: '17px',
              }}
            >
              <b>Campaña: </b>
              {stateData.object.data.nombre}
            </div>
          </div>
        )}
        {/* <Row gutter={[8, 0]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Select style={{ width: '100%' }} onChange={handleChangeCultivo}>
              {cultivo.list.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.nombre}
                </Select.Option>
              ))}
            </Select> 
          </Col>
        </Row> */}
      </Header>

      <Content>
        {Object.entries(data.columns).map(([key, value]) => {
          return (
            <>
              <Typography.Title level={4} style={{ margin: ' 10px 0 5px 5px' }}>
                {!loading && key !== 0 && key}
              </Typography.Title>
              <div>
                <MetaTable
                  rowClassName={(record, index) => {
                    if (key === 'TOTALES') {
                      return 'table-row-success';
                    }
                    if (
                      index === data.rows[key].length - 1 ||
                      index === data.rows[key].length - 2
                    ) {
                      return 'table-row-dark';
                    }
                  }}
                  dataSource={data.rows[key]}
                  dataColumns={value}
                  bordered={true}
                  selection={false}
                  cssStyle={true}
                  loading={loading}
                />
              </div>
              <Divider plain />
            </>
          );
        })}
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { campana, cultivo, inversor } = state;
  const stateData = campana;
  return {
    stateData,
    cultivo,
    inversor,
  };
};
export default connect(mapStateToProps)(withRouter(CampanaResumenInversion));
