import React from 'react';
import {
  CheckCircleFilled,
  WarningFilled,
  ClockCircleFilled,
  CodeSandboxSquareFilled,
} from '@ant-design/icons';

const StateIcon = ({ value }) => {
  switch (value) {
    case 'Finalizado':
      return <CheckCircleFilled style={{ fontSize: '16px', color: 'green' }} />;

    case 'Pendiente':
      return <WarningFilled style={{ fontSize: '16px', color: 'orange' }} />;

    case 'En proceso':
      return <ClockCircleFilled style={{ fontSize: '16px', color: 'blue' }} />;

    case 'Simulación':
      return (
        <CodeSandboxSquareFilled
          style={{ fontSize: '16px', color: 'purple' }}
        />
      );

    default:
      break;
  }
};
export default StateIcon;
