import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../components/Dashboard';
import CultivoList from '../components/CultivoList';
import InsumoList from '../components/InsumoList';
import LaborList from '../components/LaborList';
import InversorList from '../components/InversorList';
import PropietarioList from '../components/PropietarioList';
import EstablecimientoList from '../components/EstablecimientoList';
import ActividadList from '../components/ActividadList';
import ActividadInversorList from '../components/ActividadInversorList';
import ContratoList from '../components/ContratoList';
import ContratoCuotasList from '../components/ContratoCuotaList';
import OrdenTrabajoList from '../components/OrdenTrabajoList';
import OrdenTrabajoInsumoList from '../components/OrdenTrabajoInsumoList';
import ProveedorList from '../components/ProveedorList';
import CampanaList from '../components/CampanaList';
import AlquilerList from '../components/AlquilerList';

import GastoList from '../components/GastoList';
import ProduccionRetiroList from '../components/ProduccionRetiroList';
import ProduccionList from '../components/ProduccionList';

import Breadcrumbs from '../components/Breadcrumbs';
import store from '../redux/store';
import { Provider } from 'react-redux';
import ActividadResumen from '../components/ActividadResumen';
import CampanaResumenProduccion from '../components/CampanaResumenProduccion';
import CampanaResumenInversion from '../components/CampanaResumenInversion';

const r = [
  { path: '/', name: 'Home', Component: Dashboard },
  { path: '/dashboard', name: 'Home', Component: Dashboard },
  { path: '/cultivos', name: 'Cultivos', Component: CultivoList },
  { path: '/insumos', name: 'Insumos', Component: InsumoList },
  { path: '/labores', name: 'Labores', Component: LaborList },
  { path: '/inversores', name: 'Inversores', Component: InversorList },
  {
    path: '/establecimientos',
    name: 'Establecimientos',
    Component: EstablecimientoList,
  },
  { path: '/propietarios', name: 'Propietarios', Component: PropietarioList },
  { path: '/proveedores', name: 'Proveedores', Component: ProveedorList },
  { path: '/campanas', name: 'Campañas', Component: CampanaList },

  {
    path: '/campanas/produccion/:id',
    name: 'Producción',
    Component: CampanaResumenProduccion,
  },
  {
    path: '/campanas/inversion/:id',
    name: 'Inversión',
    Component: CampanaResumenInversion,
  },

  {
    path: '/campanas/alquileres/:id',
    name: 'Alquileres',
    Component: AlquilerList,
  },
  {
    path: '/campanas/alquileres/:id/contratos/:id2',
    name: 'Contratos',
    Component: ContratoList,
  },
  {
    path: '/campanas/alquileres/:id/contratos/:id2/contrato-cuotas/:id3',
    name: 'Cuotas',
    Component: ContratoCuotasList,
  },

  // {
  //   path: '/campanas/contrato/:id',
  //   name: 'Contratos',
  //   Component: ContratoList,
  // },
  // {
  //   path: '/campanas/contrato/:id/contrato-cuotas/:id2',
  //   name: 'Cuotas',
  //   Component: ContratoCuotasList,
  // },
  {
    path: '/campanas/actividades/:id',
    name: 'Actividad',
    Component: ActividadList,
  },
  {
    path: '/campanas/actividades/:id/resumen/:id2',
    name: 'Resumen',
    Component: ActividadResumen,
  },
  {
    path: '/campanas/actividades/:id/actividad-inversor/:id2',
    name: 'Inversores',
    Component: ActividadInversorList,
  },
  {
    path: '/campanas/actividades/:id/ordenes-trabajo/:id2',
    name: 'Ordenes de Trabajo',
    Component: OrdenTrabajoList,
  },
  {
    path: '/campanas/actividades/:id/ordenes-trabajo/:id2/orden-trabajo-insumos/:id3',
    name: 'Insumos',
    Component: OrdenTrabajoInsumoList,
  },
  {
    path: '/campanas/actividades/:id/gastos/:id2',
    name: 'Gastos',
    Component: GastoList,
  },
  {
    path: '/campanas/actividades/:id/produccion-retiros/:id2',
    name: 'Retiros',
    Component: ProduccionRetiroList,
  },
  {
    path: '/campanas/actividades/:id/produccion/:id2',
    name: 'Producción',
    Component: ProduccionList,
  },
];

const routes = (
  <Provider store={store}>
    <Switch>
      {r.map(({ path, name, Component }, key) => (
        <Route
          exact
          path={path}
          key={key}
          render={(props) => {
            const crumbs = r
              .filter(({ path }) => props.match.path.includes(path))
              .map(({ path, ...rest }) => ({
                path: Object.keys(props.match.params).length
                  ? Object.keys(props.match.params).reduce(
                      (path, param) =>
                        path.replace(`:${param}`, props.match.params[param]),
                      path
                    )
                  : path,
                ...rest,
              }));
            return (
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Breadcrumbs crumbs={crumbs} />
                <div style={{ flex: '1' }}>
                  <Component {...props} />
                </div>
              </div>
            );
          }}
        />
      ))}
    </Switch>
  </Provider>
);

export default routes;
