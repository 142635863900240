import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { callStatus } from '../utils/constants';

import { Layout, Row, Col, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import { campanaApi } from '../utils/api';

import MetaTable from './base/MetaTable';
import { get } from '../redux/actions/campanaActions';

const { Content, Header } = Layout;

const CampanaResumenProduccion = ({
  location,
  dispatch,
  stateData,
  cultivo,
  match: {
    params: { id },
  },
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    porcentajes: {},
    rows: { 0: [] },
    columns: {
      0: [
        {
          title: 'Establecimiento',
          dataIndex: 'establecimiento',
          key: 'establecimiento',
          width: 200,
        },
        {
          title: 'Adm',
          dataIndex: 'adm',
          key: 'establecimiento',
          width: 200,
        },
        {
          title: 'Total',
          dataIndex: 'total',
          key: 'total',
          width: 200,
        },
      ],
    },
  });

  const [dataCultivo, setDataCultivo] = useState({
    rows: [],
    columns: [],
  });

  const handleChangeCultivo = (cultivo_id) => {
    if (data.rows[cultivo_id]) {
      setDataCultivo({
        rows: data.rows[cultivo_id],
        columns: data.columns[cultivo_id],
      });
    } else {
      setDataCultivo({
        rows: [],
        columns: [],
      });
    }
  };

  const getResumen = () => {
    setLoading(true);
    campanaApi
      .get(`/resumen-produccion-cultivo/${id}`)
      .then((response) => {
        let inversores = [];

        let dataFinal = {};
        let columnsFinal = {};

        let totalFinalInv = {};
        let porcentajeFinalInv = {};

        let totalFinalAdm = {};

        response.data.forEach((cultivo, index) => {
          cultivo.actividades.forEach((actividad) => {
            actividad.resumen.inversores.forEach((inversor) => {
              const findInversor = inversores.find(
                (inv) => inv.id === inversor.inversor.id
              );
              if (!findInversor) {
                inversores.push(inversor.inversor);
              }
            });
          });

          const columns = [
            ...inversores.map((inversor) => {
              return {
                title: inversor.nombre,
                dataIndex: inversor.id,
                key: inversor.id,
                width: 200,
              };
            }),
          ];

          columnsFinal[cultivo.cultivo.id] = [
            {
              title: 'Establecimiento',
              dataIndex: 'establecimiento',
              key: 'establecimiento',
              width: 200,
            },
            ...columns,
            {
              title: 'Adm',
              dataIndex: 'adm',
              key: 'establecimiento',
              width: 200,
            },
            {
              title: 'Total',
              dataIndex: 'total',
              key: 'total',
              width: 200,
            },
          ];

          let data = [];
          let totalKilos = {};
          let totalKilosTotal = 0;
          cultivo.actividades.forEach((actividad) => {
            let inv = {};
            let totalCultivo = 0;
            let totalAdm = 0;

            inversores.forEach((inversor) => {
              const findInversor = actividad.resumen.inversores.find(
                (inv) => inv.inversor_id === inversor.id
              );
              let subtotalAdm = 0;

              if (findInversor) {
                const cantidadAdm = parseFloat(
                  (
                    (findInversor.total_cantidad *
                      findInversor.porcentaje_invertido_adm) /
                    100
                  ).toFixed(2)
                );
                inv[inversor.id] = cantidadAdm;

                subtotalAdm = findInversor.total_cantidad - cantidadAdm;

                totalKilos[inversor.id] = totalKilos[inversor.id]
                  ? parseFloat(
                      (totalKilos[inversor.id] + cantidadAdm).toFixed(2)
                    )
                  : cantidadAdm;

                totalFinalInv[inversor.id] = totalFinalInv[inversor.id]
                  ? totalFinalInv[inversor.id] + findInversor.total_invertido
                  : findInversor.total_invertido;
              } else {
                inv[inversor.id] = 0;
              }

              totalCultivo = totalCultivo + inv[inversor.id];
              totalAdm = totalAdm + subtotalAdm;
              totalKilosTotal = totalKilosTotal + inv[inversor.id];
            });

            data.push({
              ...inv,
              establecimiento: actividad.establecimiento.nombre,
              adm: totalAdm.toFixed(2),
              total: (totalCultivo + totalAdm).toFixed(2),
            });

            totalFinalAdm[cultivo.cultivo.id] = totalFinalAdm[
              cultivo.cultivo.id
            ]
              ? totalFinalAdm[cultivo.cultivo.id] + totalAdm
              : totalAdm;
          });

          totalKilos = {
            ...totalKilos,
            establecimiento: 'TOTAL KILOS',
            adm: '',
            total: totalKilosTotal.toFixed(2),
          };

          dataFinal[cultivo.cultivo.id] = [...data, { ...totalKilos }];
        });

        Object.entries(totalFinalInv).forEach(([key, value]) => {
          totalFinalInv['total'] = totalFinalInv['total']
            ? totalFinalInv['total'] + parseFloat(value)
            : parseFloat(value);
        });

        Object.entries(totalFinalInv).forEach(([key, value]) => {
          porcentajeFinalInv[key] = parseFloat(
            ((value * 100) / totalFinalInv.total).toFixed(2)
          );
        });

        Object.entries(dataFinal).forEach(([key, value]) => {
          let totalKilosCU = {};
          let totalKilosTotalCU = 0;
          Object.entries(value[value.length - 1]).forEach(([k, v]) => {
            if (k !== 'establecimiento' && k !== 'adm' && k !== 'total') {
              totalKilosCU[k] = parseFloat(
                (porcentajeFinalInv[k] * value[value.length - 1].total) / 100
              ).toFixed(2);
              totalKilosTotalCU =
                totalKilosTotalCU + parseFloat(totalKilosCU[k]);
            }
          });

          dataFinal[key] = [
            ...value,
            {
              ...totalKilosCU,
              establecimiento: 'TOTAL KILOS c/u',
              adm: totalFinalAdm[key].toFixed(2),
              total: (totalKilosTotalCU + totalFinalAdm[key]).toFixed(2),
            },
          ];
        });

        setData({
          rows: dataFinal,
          columns: columnsFinal,
          porcentajes: porcentajeFinalInv,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // const handleChangeCultivo = (cultivo_id) => {
  //   setData([]);
  //   setLoading(true);
  //   campanaApi
  //     .get(`/resumen-produccion-cultivo/${id}/${cultivo_id}`)
  //     .then((response) => {
  //       let inversores = [];

  //       response.data.forEach((actividad) => {
  //         actividad.resumen.inversores.forEach((inversor) => {
  //           const findInversor = inversores.find(
  //             (inv) => inv.id === inversor.inversor.id
  //           );
  //           if (!findInversor) {
  //             inversores.push(inversor.inversor);
  //           }
  //         });
  //       });

  //       const columns = [
  //         ...inversores.map((inversor) => {
  //           return {
  //             title: inversor.nombre,
  //             dataIndex: inversor.id,
  //             key: inversor.id,
  //             width: 200,
  //           };
  //         }),
  //       ];

  //       setColumns([
  //         {
  //           title: 'Establecimiento',
  //           dataIndex: 'establecimiento',
  //           key: 'establecimiento',
  //           width: 200,
  //         },
  //         ...columns,
  //         {
  //           title: 'Adm',
  //           dataIndex: 'adm',
  //           key: 'establecimiento',
  //           width: 200,
  //         },
  //         {
  //           title: 'Total',
  //           dataIndex: 'total',
  //           key: 'total',
  //           width: 200,
  //         },
  //       ]);

  //       let data = [];
  //       let totalKilos = {};
  //       let totalKilosTotal = 0;
  //       response.data.forEach((actividad) => {
  //         let inv = {};
  //         let totalCultivo = 0;
  //         let totalAdm = 0;

  //         inversores.forEach((inversor) => {
  //           const findInversor = actividad.resumen.inversores.find(
  //             (inv) => inv.inversor_id === inversor.id
  //           );
  //           let subtotalAdm = 0;

  //           if (findInversor) {
  //             const cantidadAdm = parseFloat(
  //               (
  //                 (findInversor.total_cantidad *
  //                   findInversor.porcentaje_invertido_adm) /
  //                 100
  //               ).toFixed(2)
  //             );
  //             inv[inversor.id] = cantidadAdm;

  //             subtotalAdm = findInversor.total_cantidad - cantidadAdm;

  //             totalKilos[inversor.id] = totalKilos[inversor.id]
  //               ? parseFloat((totalKilos[inversor.id] + cantidadAdm).toFixed(2))
  //               : cantidadAdm;
  //           } else {
  //             inv[inversor.id] = 0;
  //           }

  //           totalCultivo = totalCultivo + inv[inversor.id];
  //           totalAdm = totalAdm + subtotalAdm;
  //           totalKilosTotal = totalKilosTotal + inv[inversor.id];
  //         });

  //         data.push({
  //           ...inv,
  //           establecimiento: actividad.establecimiento.nombre,
  //           adm: totalAdm.toFixed(2),
  //           total: (totalCultivo + totalAdm).toFixed(2),
  //         });
  //       });

  //       totalKilos = {
  //         ...totalKilos,
  //         establecimiento: 'TOTAL KILOS',
  //         adm: '',
  //         total: totalKilosTotal.toFixed(2),
  //       };
  //       setData([...data, { ...totalKilos }]);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //     });
  // };

  useEffect(() => {
    dispatch(get(id));
    getResumen();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout style={{ height: '100%' }}>
      <Header
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          marginTop: '5px',
          marginBottom: '10px',
          height: '50px',
        }}
      >
        {stateData.object.status === callStatus.OBJECT_SUCCESS && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <div
              style={{
                height: '17px',
              }}
            >
              <b>Campaña: </b>
              {stateData.object.data.nombre}
            </div>
          </div>
        )}
        <Row gutter={[8, 0]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Select
              loading={loading}
              style={{ width: '100%' }}
              onChange={handleChangeCultivo}
              disabled={loading}
            >
              {cultivo.list.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.nombre}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Header>

      <Content>
        <MetaTable
          rowClassName={(record, index) => {
            if (index === dataCultivo.rows.length - 1) {
              return 'table-row-dark';
            }
          }}
          dataSource={dataCultivo.rows}
          dataColumns={dataCultivo.columns}
          bordered={false}
          selection={false}
          // cssStyle={true}
          loading={loading}
        />
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { campana, cultivo } = state;
  const stateData = campana;
  return {
    stateData,
    cultivo,
  };
};
export default connect(mapStateToProps)(withRouter(CampanaResumenProduccion));
