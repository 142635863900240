import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { get as getParent } from '../redux/actions/actividadActions';
import { callStatus } from '../utils/constants';

import { Layout, Row, Col, Descriptions, Card } from 'antd';
import { withRouter } from 'react-router-dom';
import { actividadApi } from '../utils/api';
// import {
//   PlusCircleOutlined,
//   ExclamationCircleOutlined,
//   EditFilled,
//   DeleteFilled,
// } from '@ant-design/icons';
// import styled from 'styled-components';
// import MetaTable from './base/MetaTable';
import Plot from 'react-plotly.js';
import MetaTable from './base/MetaTable';

const { Content, Header } = Layout;

const ActividadResumen = ({
  location,
  dispatch,
  stateData,
  match: {
    params: { id, id2 },
  },
  parent,
  configuracion,
}) => {
  const [data, setData] = useState({});
  const [dataTotales, setDataTotales] = useState([]);
  const [dataGastos, setDataGastos] = useState([]);
  const [dataIndicadores, setDataIndicadores] = useState([]);
  const [porcentajeAdministracion, setPorcentajeAdministracion] = useState([]);

  useEffect(() => {
    // dispatch(getAllByParent(id2));
    dispatch(getParent(id2));

    actividadApi
      .post(`/resumen/${id2}`, {})
      .then((response) => {
        setData(response.data);
        setDataTotales([
          {
            key: 'total_alquiler',
            item: 'Alquiler',
            valor1: response.data.total_alquiler,
            valor2: (
              response.data.total_alquiler / response.data.superficie
            ).toFixed(2),
          },
          {
            key: 'total_labores',
            item: 'Labores',
            valor1: response.data.total_labores,
            valor2: (
              response.data.total_labores / response.data.superficie
            ).toFixed(2),
          },
          {
            key: 'total_insumos',
            item: 'Insumos',
            valor1: response.data.total_insumos,
            valor2: (
              response.data.total_insumos / response.data.superficie
            ).toFixed(2),
          },
          {
            key: 'total_inversion',
            item: 'Invertido',
            valor1: response.data.total_inversion,
            valor2: (
              response.data.total_inversion / response.data.superficie
            ).toFixed(2),
          },
        ]);

        setDataGastos([
          {
            key: 'total_inversion_gasto',
            item: 'Alq + Labores + Insumos',
            valor1: response.data.total_inversion,
            valor2: (
              response.data.total_inversion / response.data.superficie
            ).toFixed(2),
          },
          {
            key: 'total_cosecha',
            item: 'Cosecha + Adm',
            valor1: response.data.total_cosecha,
            valor2: (
              response.data.total_cosecha / response.data.superficie
            ).toFixed(2),
          },
          {
            key: 'total_comercializacion',
            item: 'Comercialización y Flete',
            valor1: response.data.total_comercializacion,
            valor2: (
              response.data.total_comercializacion / response.data.superficie
            ).toFixed(2),
          },
          {
            key: 'total_gastos',
            item: 'Total',
            valor1: response.data.total_gastos,
            valor2: (
              response.data.total_gastos / response.data.superficie
            ).toFixed(2),
          },
          {
            key: 'total_inversion',
            item: 'Invertido',
            valor1: response.data.total_inversion,
            valor2: (
              response.data.total_inversion / response.data.superficie
            ).toFixed(2),
          },
        ]);

        setDataIndicadores([
          {
            key: 'ingresos_totales',
            item: 'Producción',
            valor1: response.data.ingresos_totales,
            valor2: (
              response.data.ingresos_totales / response.data.superficie
            ).toFixed(2),
          },
          {
            key: 'resultado_operativo',
            item: 'Resultado Opertivo',
            valor1: response.data.resultado_operativo,
            valor2: (
              response.data.resultado_operativo / response.data.superficie
            ).toFixed(2),
          },
          {
            key: 'rentabilidad',
            item: 'Rentabilidad',
            valor1: response.data.rentabilidad,
            valor2: (
              response.data.rentabilidad / response.data.superficie
            ).toFixed(2),
          },
          {
            key: 'rendimiento_indiferencia',
            item: 'Tota Rendimiento de Indiferencia',
            valor1: response.data.rendimiento_indiferencia,
            valor2: '',
          },
        ]);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (configuracion.list.status === callStatus.LIST_SUCCESS) {
      const porcentaje_administracion = configuracion.list.data.find(
        (value) => value.nombre === 'porcentaje_administracion'
      ).valor;

      setPorcentajeAdministracion(porcentaje_administracion);
    }

    // eslint-disable-next-line
  }, [configuracion.list]);

  const columnsTotales = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'superficie',
      width: '50%',
      render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
    },
    {
      title: 'USD',
      dataIndex: 'valor1',
      key: 'valor1',
      width: '25%',
    },
    {
      title: 'U$D/HA',
      dataIndex: 'valor2',
      key: 'valor2',
      width: '25%',
    },
  ];

  return (
    <Layout style={{ height: '100%' }}>
      <Header
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginTop: '5px',
        }}
      >
        {parent.object.status === callStatus.OBJECT_SUCCESS && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <div
              style={{
                height: '17px',
              }}
            >
              <b>Campaña: </b>
              {parent.object.data.campana.nombre}
            </div>

            <div
              style={{
                height: '17px',
              }}
            >
              <b>Actividad: </b>
              {parent.object.data.establecimiento.nombre}/
              {parent.object.data.cultivo.nombre}
            </div>
          </div>
        )}
      </Header>
      <Content>
        <Row gutter={[8, 0]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card bordered={false} bodyStyle={{ padding: 0 }}>
              <Descriptions
                bordered
                size="small"
                column={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                labelStyle={{ fontSize: '16px', fontWeight: 'bold' }}
              >
                <Descriptions.Item label="Total Superficie">
                  {data.superficie} HA
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <Card
              title="Totales"
              bordered={false}
              bodyStyle={{ padding: 0 }}
              style={{ marginTop: '8px' }}
            >
              {/* <Descriptions
                bordered
                size="small"
                column={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                <Descriptions.Item label="Total Superficie">
                  {data.superficie} HA
                </Descriptions.Item>
                <Descriptions.Item label="Total Alquiler">
                  U$S {data.total_alquiler}
                </Descriptions.Item>
                <Descriptions.Item label="Total Labores">
                  U$S {data.total_labores}
                </Descriptions.Item>
                <Descriptions.Item label="Total Insumos">
                  U$S {data.total_insumos}
                </Descriptions.Item>
                <Descriptions.Item label="Total Invertido">
                  U$S {data.total_inversion}
                </Descriptions.Item>
              </Descriptions> */}

              <MetaTable
                dataSource={dataTotales}
                dataColumns={columnsTotales}
                bordered={true}
                selection={false}
                cssStyle={true}
                // loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
              />
            </Card>
            <Card
              title="Gastos"
              bordered={false}
              bodyStyle={{ padding: 0 }}
              style={{ marginTop: '8px' }}
            >
              {/* <Descriptions
                bordered
                size="small"
                column={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                <Descriptions.Item label="Alq + Labores + Insumos">
                  U$S {data.total_inversion}
                </Descriptions.Item>
                <Descriptions.Item label="Cosecha + Adm">
                  U$S {data.total_cosecha}
                </Descriptions.Item>
                <Descriptions.Item label="Comercialización y Flete">
                  U$S {data.total_comercializacion}
                </Descriptions.Item>
                <Descriptions.Item label="Total">
                  U$S {data.total_gastos}
                </Descriptions.Item>
              </Descriptions> */}
              <MetaTable
                dataSource={dataGastos}
                dataColumns={columnsTotales}
                bordered={true}
                selection={false}
                cssStyle={true}
                // loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
              />
            </Card>
            <Card
              title="Indicadores"
              bordered={false}
              bodyStyle={{ padding: 0 }}
              style={{ marginTop: '8px' }}
            >
              {/* <Descriptions
                bordered
                size="small"
                column={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                <Descriptions.Item label="Producción">
                  U$S {data.ingresos_totales}
                </Descriptions.Item>
                <Descriptions.Item label="Resultado Opertivo">
                  U$S {data.resultado_operativo}
                </Descriptions.Item>
                <Descriptions.Item label="Rentabilidad">
                  U$S {data.rentabilidad}
                </Descriptions.Item>
                <Descriptions.Item label="Rendimiento de Indef">
                  U$S/HA {data.rendimiento_indiferencia}
                </Descriptions.Item>
              </Descriptions> */}

              <MetaTable
                dataSource={dataIndicadores}
                dataColumns={columnsTotales}
                bordered={true}
                selection={false}
                cssStyle={true}
                // loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card
              title="Porcentaje Inversores"
              bordered={false}
              bodyStyle={{ padding: 0 }}
            >
              <Descriptions
                title="Porcentajes sin Administración"
                bordered
                size="small"
                column={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                {data.inversores &&
                  data.inversores.map((item, index) => (
                    <Descriptions.Item label={item.nombre} key={index}>
                      {item.porcentaje_invertido} %
                    </Descriptions.Item>
                  ))}
              </Descriptions>

              <Descriptions
                title="Porcentajes con Administración"
                bordered
                size="small"
                column={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                {data.inversores &&
                  data.inversores.map((item, index) => (
                    <Descriptions.Item label={item.nombre} key={index}>
                      {item.porcentaje_invertido_adm} %
                    </Descriptions.Item>
                  ))}
                <Descriptions.Item label="Administración">
                  {porcentajeAdministracion}%
                </Descriptions.Item>
              </Descriptions>

              <div>
                <Plot
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  data={[
                    {
                      values: data.inversores && [
                        ...data.inversores.map(
                          (item) => item.porcentaje_invertido_adm
                        ),
                        3,
                      ],
                      labels: data.inversores && [
                        ...data.inversores.map((item) => item.nombre),
                        'Administración',
                      ],
                      type: 'pie',
                    },
                  ]}
                  layout={{ autosize: true }}
                />
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card
              title="Totales Inversores"
              bordered={false}
              bodyStyle={{ padding: 0 }}
            >
              {data.inversores &&
                data.inversores.map((item, index) => (
                  <Descriptions
                    title={item.nombre}
                    bordered
                    size="small"
                    column={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    key={index}
                  >
                    <Descriptions.Item label="Alquiler">
                      U$S {item.total_alquileres}
                    </Descriptions.Item>
                    <Descriptions.Item label="Labores">
                      U$S {item.total_labores}
                    </Descriptions.Item>
                    <Descriptions.Item label="Insumos">
                      U$S {item.total_insumos}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Total Invertido"
                      style={{ fontWeight: 'bold' }}
                    >
                      U$S {item.total_invertido}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Producción TN"
                      style={{ fontWeight: 'bold' }}
                    >
                      {item.total_cantidad} TN
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Producción USD"
                      style={{ fontWeight: 'bold' }}
                    >
                      {item.total_produccion} U$D
                    </Descriptions.Item>

                    <Descriptions.Item
                      label="Hectáreas Cosechadas"
                      style={{ fontWeight: 'bold' }}
                    >
                      {item.total_cosecha} HA
                    </Descriptions.Item>
                  </Descriptions>
                ))}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}></Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { actividadInversor, actividad, configuracion } = state;
  const stateData = actividadInversor;
  const parent = actividad;
  return {
    stateData,
    parent,
    configuracion,
  };
};
export default connect(mapStateToProps)(withRouter(ActividadResumen));
