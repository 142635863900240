import { Modal } from 'antd';
const { success, error } = Modal;

export const Notificacion = (message, type, handler) => {
  switch (type) {
    case 'success':
      success({
        title: message,
        onOk() {
          handler();
        },
      });
      break;
    case 'error':
      error({
        title: message,
        onOk() {
          handler();
        },
      });
      break;
    default:
      break;
  }
};
