import { proveedorApi } from '../../utils/api';
import {
  getObjectDataAction,
  createObjectDataPending,
  getListDataAction,
  saveObjectDataAction,
  deleteObjectDataAction,
  getListDeletedDataAction,
  createListDataDeletedPending,
  restoreObjectDataAction,
  filterListDataAction,
} from '../../utils/DataAction';

export const get = (id) =>
  getObjectDataAction({
    dataName: 'PROVEEDOR',
    api: proveedorApi,
    relUrl: id,
  });

export const getAll = getListDataAction({
  dataName: 'PROVEEDOR',
  api: proveedorApi,
  relUrl: '',
});

export const save = (data) =>
  saveObjectDataAction({
    dataName: 'PROVEEDOR',
    api: proveedorApi,
    relUrl: '',
    data: data,
  });

export const deleted = (id) =>
  deleteObjectDataAction({
    dataName: 'PROVEEDOR',
    api: proveedorApi,
    relUrl: id,
  });

export const getAllDeleted = getListDeletedDataAction({
  dataName: 'PROVEEDOR',
  api: proveedorApi,
  relUrl: 'restore',
});

export const restore = (data) =>
  restoreObjectDataAction({
    dataName: 'PROVEEDOR',
    api: proveedorApi,
    relUrl: 'restore',
    data: data,
  });

export const filter = (data) =>
  filterListDataAction({
    dataName: 'PROVEEDOR',
    api: proveedorApi,
    relUrl: 'filter',
    data: data,
  });

export const setPending = createObjectDataPending('PROVEEDOR');
export const setDeletedPending = createListDataDeletedPending('PROVEEDOR');
