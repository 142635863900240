import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Space,
  Spin,
  Card,
} from 'antd';
import { get, save } from '../redux/actions/alquilerActions';
import {
  ExclamationCircleOutlined,
  DeleteFilled,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
import { callStatus } from '../utils/constants';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import dayjs from 'dayjs';
import MetaTable from './base/MetaTable';
import ActividadesAsociadasPopup from './ActividadesAsociadasPopup';
import PropietariosAsociadosPopup from './PropietariosAsociadosPopup';
import { Notificacion } from './Notificacion';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
const { confirm } = Modal;

const AlquilerEditor = ({
  dispatch,
  id,
  stateData,
  visible,
  onCancel,
  parentId,
}) => {
  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';

  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);
    data['fecha'] = moment(moment(data.fecha));
    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      data['fecha'] = moment(moment(data.fecha));

      data['alquiler_propietarios'] = data.alquiler_propietarios.map((item) => {
        return {
          ...item,
          key: item.propietario_id,
          deleted: false,
        };
      });

      data['alquiler_actividades'] = data.alquiler_actividades.map((item) => {
        return {
          ...item,
          key: item.actividad_id,
          deleted: false,
        };
      });

      setPropietarios(data['alquiler_propietarios']);
      setActividades(data['alquiler_actividades']);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      Notificacion('El registro se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      Notificacion(stateData.object.error, 'error', () => {});
    }
    form.setFieldsValue(data);
    setInitial(data);
    // eslint-disable-next-line
  }, [stateData.object]);

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);
    data.campana_id = parentId;
    // console.log(data);

    let errorActividad = false;
    data.actividades.forEach((item) => {
      if (!item.superficie) {
        errorActividad = true;
      }
    });

    let errorPropietario = false;
    data.propietarios.forEach((item) => {
      if (!item.superficie) {
        errorPropietario = true;
      }
    });

    if (errorActividad) {
      Notificacion(
        'Todas las actividades deben tener seteadas una superficie',
        'error',
        () => {}
      );
    } else if (errorPropietario) {
      Notificacion(
        'Todos los propietarios deben tener seteados una superficie',
        'error',
        () => {}
      );
    } else {
      dispatch(save(data));
    }
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  // ACTIVIDADES ////////////////////////////////////////////////////////////////

  const [showAddActividades, setShowAddActividades] = useState(false);
  const [actividades, setActividades] = useState([]);

  const onSuperficieActividadChange = (e, key, index, record) => {
    let newData = [...actividades];
    newData = [
      ...newData.map((item) => {
        if (item.actividad_id === record.actividad_id) {
          return { ...item, superficie: e.target.value };
        } else {
          return item;
        }
      }),
    ];

    setActividades(newData);
  };

  const columnsActividad = [
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <DeleteFilled onClick={() => handleConfirmDeleteActividad(record)} />
        </Space>
      ),
      width: 30,
    },
    // {
    //   width: 60,
    //   title: 'KEY',
    //   dataIndex: 'key',
    // },
    // {
    //   width: 60,
    //   title: 'ID',
    //   dataIndex: 'id',
    // },
    // {
    //   width: 60,
    //   title: 'ActId',
    //   dataIndex: 'actividad_id',
    // },
    {
      width: 200,
      title: 'Establecimiento',
      dataIndex: 'establecimiento_nombre',
    },
    {
      width: 100,
      title: 'Cultivo',
      dataIndex: 'cultivo_nombre',
    },
    {
      width: 100,
      title: 'Superficie',
      dataIndex: 'superficie',
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) =>
            onSuperficieActividadChange(e, 'superficie', index, record)
          }
        />
      ),
    },
  ];

  const handleActividadesCancel = () => {
    setShowAddActividades(false);
  };

  const handleActividadesOk = (data) => {
    let actividades = form.getFieldValue('actividades')
      ? form.getFieldValue('actividades')
      : [];

    let actividadPropietarios = [];

    actividades = [
      ...actividades,
      ...data.map((item) => {
        if (
          actividades.some(
            (value) =>
              value.actividad_id === item.actividad_id && !value.deleted
          )
        ) {
          return null;
        } else {
          actividadPropietarios = [
            ...actividadPropietarios,
            ...item.propietarios,
          ];
          return {
            ...item,
          };
        }
      }),
    ];

    setShowAddActividades(false);

    setActividades(actividades.filter((item) => item !== null));

    calcularSuperficie();

    // setPropietarios((prev) => {
    //   return [...prev, ...actividadPropietarios];
    // });

    let resultPropietarios = [];

    let totalPropietarios = [...propietarios, ...actividadPropietarios];

    totalPropietarios.forEach((value) => {
      let repetidos = totalPropietarios.filter(
        (item) => value.propietario_id === item.propietario_id && !item.deleted
      );

      let superficie = 0;
      repetidos.forEach((item) => {
        superficie = superficie + parseFloat(item.superficie);
      });

      if (
        repetidos.length > 0 &&
        !resultPropietarios.some(
          (item) => value.propietario_id === item.propietario_id
        )
      ) {
        resultPropietarios = [
          ...resultPropietarios,
          { ...repetidos[0], superficie: superficie },
        ];
      }
    });
    setPropietarios(resultPropietarios);
  };

  const calcularSuperficie = () => {
    if (!stateData.object.data.id) {
      let total = 0;

      if (form.getFieldValue('actividades')) {
        form.getFieldValue('actividades').forEach((item) => {
          total = total + parseFloat(item.superficie);
        });
      }

      form.setFieldsValue({
        superficie: total.toFixed(2),
      });
    }
  };

  const handleActividadesSearch = () => {
    setShowAddActividades(true);
  };

  const handleDeleteActividad = (data) => {
    // let newPropietarios = [...propietarios];

    let otrasActividades = [
      ...actividades.filter((item) => item.actividad_id !== data.actividad_id),
    ];

    let deletePropietarios = [];

    data.propietarios.forEach((propData) => {
      otrasActividades.forEach((act) => {
        if (
          act.deleted ||
          !act.propietarios.some(
            (item) => item.propietario_id === propData.propietario_id
          )
        ) {
          deletePropietarios = [...deletePropietarios, propData];
        }
      });
    });

    let newDataProp = [...propietarios];

    deletePropietarios.forEach((prop) => {
      newDataProp = [
        ...newDataProp.map((item) => {
          if (item.propietario_id === prop.propietario_id) {
            return { ...item, deleted: true };
          } else {
            return item;
          }
        }),
      ];
    });

    setPropietarios(newDataProp);

    let newData = [...actividades];
    newData = [
      ...newData.map((item) => {
        if (item.actividad_id === data.actividad_id) {
          return { ...item, deleted: true };
        } else {
          return item;
        }
      }),
    ];

    setActividades(newData);
  };

  useEffect(() => {
    form.setFieldsValue({
      actividades: actividades,
    });
    calcularSuperficie();
    // eslint-disable-next-line
  }, [actividades]);

  const handleConfirmDeleteActividad = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDeleteActividad(item);
      },
      onCancel() {},
    });
  };

  // PROPIETARIOS ////////////////////////////////////////////////////////////////

  const [showAddPropietarios, setShowAddPropietarios] = useState(false);
  const [propietarios, setPropietarios] = useState([]);

  const onSuperficiePropietarioChange = (e, key, index, record) => {
    let newData = [...propietarios];
    newData = [
      ...newData.map((item) => {
        if (item.propietario_id === record.propietario_id) {
          return { ...item, superficie: e.target.value };
        } else {
          return item;
        }
      }),
    ];

    setPropietarios(newData);
  };

  const columnsPropietario = [
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <DeleteFilled
            onClick={() => handleConfirmDeletePropietario(record)}
          />
        </Space>
      ),
      width: 30,
    },
    // {
    //   width: 60,
    //   title: 'KEY',
    //   dataIndex: 'key',
    // },
    // {
    //   width: 60,
    //   title: 'ID',
    //   dataIndex: 'id',
    // },
    // {
    //   width: 60,
    //   title: 'ProID',
    //   dataIndex: 'propietario_id',
    // },
    {
      width: 300,
      title: 'Nombre',
      dataIndex: 'propietario_nombre',
    },
    {
      width: 100,
      title: 'Superficie',
      dataIndex: 'superficie',
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) =>
            onSuperficiePropietarioChange(e, 'superficie', index, record)
          }
        />
      ),
    },
  ];

  const handlePropietariosCancel = () => {
    setShowAddPropietarios(false);
  };

  const handlePropietariosOk = (data) => {
    let propietarios = form.getFieldValue('propietarios')
      ? form.getFieldValue('propietarios')
      : [];

    let resultPropietarios = [];

    data.forEach((value) => {
      let repetidos = data.filter(
        (item) => value.propietario_id === item.propietario_id
      );

      let superficie = 0;
      repetidos.forEach((item) => {
        superficie = superficie + parseFloat(item.superficie);
      });

      if (
        !resultPropietarios.some(
          (item) => value.propietario_id === item.propietario_id
        )
      ) {
        resultPropietarios = [
          ...resultPropietarios,
          { ...repetidos[0], superficie: superficie },
        ];
      }
    });

    propietarios = [
      ...propietarios,
      ...resultPropietarios.map((item) => {
        if (
          propietarios.some(
            (value) =>
              value.propietario_id === item.propietario_id && !value.deleted
          )
        ) {
          return null;
        } else {
          return {
            ...item,
          };
        }
      }),
    ];

    setShowAddPropietarios(false);

    setPropietarios(propietarios.filter((item) => item !== null));
  };

  const handlePropietariosSearch = () => {
    setShowAddPropietarios(true);
  };

  const handleDeletePropietario = (data) => {
    let newData = [...propietarios];
    newData = [
      ...newData.map((item) => {
        if (item.propietario_id === data.propietario_id) {
          return { ...item, deleted: true };
        } else {
          return item;
        }
      }),
    ];

    setPropietarios(newData);
  };

  useEffect(() => {
    form.setFieldsValue({
      propietarios: propietarios,
    });
    calcularSuperficie();
    // eslint-disable-next-line
  }, [propietarios]);

  const handleConfirmDeletePropietario = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDeletePropietario(item);
      },
      onCancel() {},
    });
  };
  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          mask
          initialWidth={750}
          initialHeight={750}
          maskClosable={false}
          visible={visible}
          title={
            stateData.object.data.id ? 'Editar Alquiler' : 'Nuevo Alquiler'
          }
          okText="Guardar"
          cancelText="Salir"
          onCancel={handleOnCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          {' '}
          <Spin
            spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={stateData.object.data}
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="id" label="id" style={{ display: 'none' }}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="fecha" label="Fecha">
                    <DatePicker
                      format={formatDisplay}
                      style={{ width: '100%' }}
                      initialValue={moment(new Date())}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="superficie"
                    label="Superficie"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Card
                    extra={
                      <PlusCircleOutlined
                        style={{ fontSize: '18px' }}
                        onClick={() => handleActividadesSearch()}
                      />
                    }
                    title={
                      <span style={{ marginLeft: '3px' }}>Actividades</span>
                    }
                    style={{ marginBottom: '10px' }}
                    size="small"
                    bodyStyle={{ padding: 0 }}
                  >
                    <Row>
                      <Form.List
                        name="actividades"
                        rules={[
                          {
                            validator: async (_, actividades) => {
                              if (!actividades || actividades.length < 1) {
                                return Promise.reject(
                                  new Error(
                                    'Seleccione al menos un comprobante'
                                  )
                                );
                              }
                            },
                          },
                        ]}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            {errors.length > 0 && (
                              <Row
                                gutter={[0, 0]}
                                style={{ width: '100%' }}
                                align="top"
                                justify="center"
                              >
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <Form.Item>
                                    <Form.ErrorList errors={errors} />
                                  </Form.Item>
                                </Col>
                              </Row>
                            )}
                          </>
                        )}
                      </Form.List>
                      {actividades && actividades.length > 0 && (
                        <MetaTable
                          dataSource={actividades.filter(
                            (item) => !item.deleted
                          )}
                          dataColumns={columnsActividad}
                          bordered={false}
                          selection={false}
                          cssStyle={true}
                          customKey={'actividad_id'}
                        />
                      )}
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Card
                    extra={
                      <PlusCircleOutlined
                        style={{ fontSize: '18px' }}
                        onClick={() => handlePropietariosSearch()}
                      />
                    }
                    title={
                      <span style={{ marginLeft: '3px' }}>Propietarios</span>
                    }
                    style={{ marginBottom: '10px' }}
                    size="small"
                    bodyStyle={{ padding: 0 }}
                  >
                    <Row>
                      <Form.List
                        name="propietarios"
                        rules={[
                          {
                            validator: async (_, propietarios) => {
                              if (!propietarios || propietarios.length < 1) {
                                return Promise.reject(
                                  new Error(
                                    'Seleccione al menos un propietario'
                                  )
                                );
                              }
                            },
                          },
                        ]}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            {errors.length > 0 && (
                              <Row
                                gutter={[0, 0]}
                                style={{ width: '100%' }}
                                align="top"
                                justify="center"
                              >
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <Form.Item>
                                    <Form.ErrorList errors={errors} />
                                  </Form.Item>
                                </Col>
                              </Row>
                            )}
                          </>
                        )}
                      </Form.List>
                      {propietarios && propietarios.length > 0 && (
                        <MetaTable
                          dataSource={propietarios.filter(
                            (item) => !item.deleted
                          )}
                          dataColumns={columnsPropietario}
                          bordered={false}
                          selection={false}
                          cssStyle={true}
                          customKey={'propietario_id'}
                        />
                      )}
                    </Row>
                  </Card>
                </Col>
              </Row>

              {showAddActividades ? (
                <ActividadesAsociadasPopup
                  parentId={parentId}
                  onCancel={handleActividadesCancel}
                  onOk={handleActividadesOk}
                  itemSelected={form.getFieldValue('actividades')}
                />
              ) : (
                false
              )}

              {showAddPropietarios ? (
                <PropietariosAsociadosPopup
                  actividades={actividades.filter((item) => !item.deleted)}
                  onCancel={handlePropietariosCancel}
                  onOk={handlePropietariosOk}
                  itemSelected={form.getFieldValue('propietarios')}
                />
              ) : (
                false
              )}
            </Form>
          </Spin>
        </DraggableModal>
      </DraggableModalProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  const { alquiler } = state;
  const stateData = alquiler;
  return {
    stateData,
  };
};
export default connect(mapStateToProps)(AlquilerEditor);
