import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Row, Col, Select, DatePicker, Spin } from 'antd';
import { get, save } from '../redux/actions/ordenTrabajoActions';
import { save as saveNumero } from '../redux/actions/configuracionActions';
// import { filter as filterProveedor } from '../redux/actions/proveedorActions';

import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
import { callStatus } from '../utils/constants';
import { Notificacion } from './Notificacion';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;

const OrdenTrabajoEditor = ({
  dispatch,
  id,
  parentId,
  stateData,
  visible,
  onCancel,
  proveedor,
  labor,
  inversor,
  ordenTrabajoEstado,
  configuracion,
}) => {
  const [numeroOrden, setNumeroOrden] = useState('');
  const [form] = Form.useForm();

  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';

  const [proveedorData, setProveedorData] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // dispatch(filterProveedor({ data: [{ name: 'tipo_id', value: '2' }] }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setProveedorData(
      proveedor.list.data.filter(
        (item) => item.tipo_id === 2 || item.tipo_id === 3
      )
    );
    // eslint-disable-next-line
  }, [proveedor]);

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);

    data['fecha'] = moment(moment(data.fecha));

    if (!id) {
      const numero =
        parseInt(
          configuracion.list.data.find(
            (value) => value.nombre === 'orden_trabajo_num'
          ).valor
        ) + 1;

      setNumeroOrden(numero);

      data['numero'] = numero.toString().padStart(8, '0');
      data['estado_id'] = 1;
    }

    form.setFieldsValue(data);
    setInitial(data);

    if (stateData.object.data.proveedor) {
      setResultSearchProveedor([stateData.object.data.proveedor]);
    }
    if (stateData.object.data.labor) {
      setResultSearchLabor([stateData.object.data.labor]);
    }
    // eslint-disable-next-line
  }, [stateData]);

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);
    data['fecha'] = moment(moment(data.fecha));

    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      data['fecha'] = moment(moment(data.fecha));

      if (!id) {
        const numero =
          parseInt(
            configuracion.list.data.find(
              (value) => value.nombre === 'orden_trabajo_num'
            ).valor
          ) + 1;

        setNumeroOrden(numero);

        data['numero'] = numero.toString().padStart(8, '0');
      }

      form.setFieldsValue(data);
      setInitial(data);

      if (stateData.object.data.proveedor) {
        setResultSearchProveedor([data.proveedor]);
      }
      if (stateData.object.data.labor) {
        setResultSearchLabor([data.labor]);
      }
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      Notificacion('El registro se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      Notificacion(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);
    data.actividad_id = parentId;

    if (
      ((data.estado_id === 3 || data.estado_id === 4) && !data.importe) ||
      !data.inversor_id
    ) {
      Notificacion(
        'Para Finalizar o Simular debe completar Importe e Inversor ',
        'error',
        () => {}
      );
    } else {
      dispatch(save(data)).then((response) => {
        if (!id) {
          dispatch(saveNumero({ id: 1, valor: numeroOrden }));
        }
      });
    }
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };
  const [resultSearchProveedor, setResultSearchProveedor] = useState([]);
  const [resultSearchLabor, setResultSearchLabor] = useState([]);

  const onSearchProveedor = (value) => {
    const result = proveedorData.filter((item) =>
      item.nombre.toLowerCase().includes(value.toLowerCase())
    );
    setResultSearchProveedor(result);
  };

  const onSearchLabor = (value) => {
    const result = labor.list.data.filter((item) =>
      item.nombre.toLowerCase().includes(value.toLowerCase())
    );
    setResultSearchLabor(result);
  };

  const handleChangeProveedor = (value) => {
    const prov = proveedorData.find((item) => item.id === value);
    if (prov) {
      form.setFieldsValue({ precio_superficie: prov.precio_superficie });
    }
  };

  const calcImporte = () => {
    const superficie = form.getFieldValue('superficie');
    const precio_superficie = form.getFieldValue('precio_superficie');
    const dolar = form.getFieldValue('dolar');

    if (superficie && precio_superficie && dolar) {
      const importe = (superficie * precio_superficie) / dolar;
      form.setFieldsValue({ importe: importe.toFixed(2) });
    }
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          mask
          initialWidth={700}
          initialHeight={700}
          maskClosable={false}
          visible={visible}
          title={
            stateData.object.data.id
              ? 'Editar Orden de Trabajo'
              : 'Nueva Orden de Trabajo'
          }
          okText="Guardar"
          cancelText="Salir"
          onCancel={handleOnCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Spin
            spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={stateData.object.data}
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="id" label="id" style={{ display: 'none' }}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item
                    name="fecha"
                    label="Fecha"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <DatePicker
                      format={formatDisplay}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item
                    name="numero"
                    label="Número"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item
                    name="estado_id"
                    label="Estado"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select>
                      {ordenTrabajoEstado.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="proveedor_id"
                    label="Contratista"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={onSearchProveedor}
                      onChange={handleChangeProveedor}
                      notFoundContent={null}
                      allowClear
                    >
                      {resultSearchProveedor.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.nombre}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="labor_id"
                    label="Labor"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={onSearchLabor}
                      onChange={() => {}}
                      notFoundContent={null}
                      allowClear
                    >
                      {resultSearchLabor.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.nombre}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="superficie"
                    label="Superficie"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      addonBefore="ha"
                      onBlur={calcImporte}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="precio_superficie"
                    label="Precio por Superficie"
                  >
                    <Input type="number" addonBefore="$" onBlur={calcImporte} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="dolar" label="Dolar">
                    <Input
                      type="number"
                      addonBefore="U$D"
                      onBlur={calcImporte}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="importe" label="Importe">
                    <Input type="number" addonBefore="U$D" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="inversor_id" label="Inversor">
                    <Select allowClear>
                      {inversor.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="observacion" label="Observación">
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </DraggableModal>
      </DraggableModalProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    ordenTrabajo,
    proveedor,
    labor,
    inversor,
    ordenTrabajoEstado,
    configuracion,
  } = state;
  const stateData = ordenTrabajo;
  return {
    stateData,
    proveedor,
    labor,
    inversor,
    ordenTrabajoEstado,
    configuracion,
  };
};
export default connect(mapStateToProps)(OrdenTrabajoEditor);
