import {
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGIN_IN_PROCESS,
  LOGIN_FAILED,
} from './AuthActions';

import { callStatus } from '../utils/constants';

const initialState = localStorage.auth
  ? {
      data: JSON.parse(localStorage.auth),
      status: callStatus.SUCCESS,
      errors: '',
    }
  : {
      data: {},
      status: callStatus.PENDING,
      errors: '',
    };

const AuthReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case LOGIN_PENDING:
      newState = {
        ...state,
        status: callStatus.PENDING,
        errors: '',
      };

      return newState;
    case LOGIN_IN_PROCESS:
      newState = {
        ...state,
        status: callStatus.IN_PROCESS,
        errors: '',
      };

      return newState;
    case LOGIN_SUCCESS:
      newState = {
        ...state,
        data: action.data,
        status: callStatus.SUCCESS,
        errors: '',
      };

      localStorage.setItem('auth', JSON.stringify(action.data));

      return newState;
    case LOGIN_FAILED:
      newState = {
        ...state,
        status: callStatus.FAILED,
        errors: action.data,
      };
      return newState;

    default:
      return state;
  }
};

export default AuthReducer;
