import React, { useState, useEffect } from 'react';
import ProduccionRetiroEditor from './ProduccionRetiroEditor';
import { connect } from 'react-redux';
// import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  getAllByParent,
  setPending,
  deleted,
  // setDeletedPending,
  // getAllDeleted,
  // restore,
} from '../redux/actions/produccionRetiroActions';
import { get as getParent } from '../redux/actions/actividadActions';
import { callStatus } from '../utils/constants';
import {
  // Table,
  // Input,
  Button,
  Space,
  // Card,
  Menu,
  Dropdown,
  Row,
  Col,
  Layout,
  Modal,
} from 'antd';
import { withRouter } from 'react-router-dom';
// import Highlighter from 'react-highlight-words';
import {
  // SearchOutlined,
  EditFilled,
  DeleteFilled,
  // DownloadOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
  FormatPainterFilled,
  // SelectOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import BaseBox from './base/BaseBox';
import MetaTable from './base/MetaTable';
import dayjs from 'dayjs';
import moment from 'moment';
import { convertDate } from '../utils/funtions';
// import StateIcon from './base/StateIcon';
const { confirm } = Modal;

const { Content, Header, Footer } = Layout;

// const HeaderContainer = styled.div`
//   display: flex;
//   align-items: center;
//   width: 100%;
// `;

const SelectedContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

const MenuButton = styled(Button)`
  width: 100%;
  text-align: left;
`;

const ProduccionRetiroList = ({
  location,
  stateData,
  dispatch,
  match: {
    params: { id, id2 },
  },
  parent,
}) => {
  const [view, setView] = useState(1);

  const [showEdit, setShowEdit] = useState(false);

  const [rowEdit, setRowEdit] = useState(null);

  const [dataSource, setDataSource] = useState([]);

  const formatDisplay = 'DD/MM/YYYY';
  useEffect(() => {
    dispatch(getAllByParent(id2));
    dispatch(getParent(id2));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (stateData.list.status === callStatus.LIST_SUCCESS) {
      const data = [
        ...stateData.list.data.map((item) => {
          return {
            ...item,
            fecha: dayjs(
              new Date(moment(moment(convertDate(item.fecha)))).toISOString()
            ).format(formatDisplay),
          };
        }),
      ];

      setDataSource(data);
    }

    // eslint-disable-next-line
  }, [stateData]);

  const handleEdit = (data) => {
    setRowEdit(data);
    setShowEdit(true);
  };

  const handleDelete = (data) => {
    dispatch(deleted(data.id));
  };

  const handleHideEditor = () => {
    setShowEdit(false);
    setRowEdit(null);
    dispatch(setPending());
  };

  const handleConfirm = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={(e) => handleEdit(record)} />
          <DeleteFilled onClick={(e) => handleConfirm(record)} />
        </Space>
      ),
      width: 90,
    },
    {
      width: 100,
      ellipsis: true,
      title: 'Fecha',
      dataIndex: 'fecha',
    },

    {
      width: 100,
      ellipsis: true,
      title: 'Cantidad Tn',
      dataIndex: 'cantidad',
    },
    {
      width: 100,
      ellipsis: true,
      title: 'Precio Cantidad $',
      dataIndex: 'precio_unitario',
    },
    {
      width: 100,
      ellipsis: true,
      title: 'Dolar',
      dataIndex: 'dolar',
    },
    {
      width: 100,
      ellipsis: true,
      title: 'Importe U$D',
      dataIndex: 'importe',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Camionero',
      dataIndex: 'camionero',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Destino',
      dataIndex: 'destino_nombre',
    },

    {
      width: 200,
      ellipsis: true,
      title: 'Inversor',
      dataIndex: 'inversor_nombre',
    },
  ];

  const grid = { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 };

  const menuView = (
    <Menu>
      <Menu.Item key="1">
        <MenuButton onClick={() => setView(1)} icon={<UnorderedListOutlined />}>
          Tabla
        </MenuButton>
      </Menu.Item>
      <Menu.Item key="2">
        <MenuButton onClick={() => setView(2)} icon={<UnorderedListOutlined />}>
          Box
        </MenuButton>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ height: '100%' }}>
      <Header
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginTop: '5px',
        }}
      >
        {parent.object.status === callStatus.OBJECT_SUCCESS && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <div
              style={{
                height: '17px',
              }}
            >
              <b>Campaña: </b>
              {parent.object.data.campana.nombre}
            </div>

            <div
              style={{
                height: '17px',
              }}
            >
              <b>Actividad: </b>
              {parent.object.data.establecimiento.nombre}/
              {parent.object.data.cultivo.nombre}
            </div>
          </div>
        )}
      </Header>
      <Content>
        {view === 1 ? (
          <MetaTable
            dataSource={dataSource}
            dataColumns={columns}
            bordered={false}
            selection={false}
            loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
          />
        ) : (
          <Row gutter={8}>
            {stateData.list.data.map((item) => {
              return (
                <Col {...grid} key={item.id} style={{ marginBottom: '10px' }}>
                  <BaseBox
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    item={item}
                    title={item.establecimiento_nombre}
                    subtitle={item.cultivo_nombre}
                    moreInfo={item.campana}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      </Content>
      <Footer>
        <FooterContainer>
          <SelectedContainer>
            {/* {selectedRowKeys.length > 0
                ? `${selectedRowKeys.length} filas seleccionadas`
                : 'No hay files seleccionadas'} */}
          </SelectedContainer>
          <ActionsContainer>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={(e) => handleEdit({})}
            >
              Agregar
            </Button>

            <Dropdown overlay={menuView}>
              <Button
                type="primary"
                icon={<FormatPainterFilled />}
                size="small"
                style={{ marginLeft: '3px' }}
              >
                Vista
              </Button>
            </Dropdown>
          </ActionsContainer>
        </FooterContainer>
      </Footer>
      {showEdit ? (
        <ProduccionRetiroEditor
          id={rowEdit.id}
          parentId={id2}
          onCancel={handleHideEditor}
          // onCreate={handleSaveEditor}
          visible={true}
        />
      ) : (
        false
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { produccionRetiro, actividad } = state;
  const stateData = produccionRetiro;
  const parent = actividad;

  return {
    stateData,
    parent,
  };
};
export default connect(mapStateToProps)(withRouter(ProduccionRetiroList));
