import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Spin,
  Card,
  Space,
} from 'antd';
import { get, save } from '../redux/actions/actividadActions';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined, DeleteFilled } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
import { callStatus } from '../utils/constants';
import { Notificacion } from './Notificacion';
import styled from 'styled-components';
import MetaTable from './base/MetaTable';
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';

const { Option } = Select;

const { confirm } = Modal;

const TitleCard = styled.div`
  display: flex;
  align-items: center;
`;

const ActividadEditor = ({
  dispatch,
  id,
  stateData,
  visible,
  onCancel,
  establecimiento,
  cultivo,
  parentId,
  inversor,
}) => {
  const [form] = Form.useForm();

  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);
    data['fecha'] = moment(moment(data.fecha));

    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      data['fecha'] = moment(moment(data.fecha));

      if (stateData.object.data.establecimiento) {
        setResultSearchEstablecimiento([stateData.object.data.establecimiento]);
      }

      if (stateData.object.data.inversores) {
        setInversores(stateData.object.data.inversores);
      }
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      Notificacion('El registro se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      Notificacion(stateData.object.error, 'error', () => {});
    }
    form.setFieldsValue(data);
    setInitial(data);
    // eslint-disable-next-line
  }, [stateData.object]);

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);
    data.campana_id = parentId;
    dispatch(save(data));
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };
  const [establecimientoData, setEstablecimientoData] = useState([]);
  const [resultSearchEstablecimiento, setResultSearchEstablecimiento] =
    useState([]);

  const onSearchEstablecimiento = (value) => {
    const result = establecimientoData.filter((item) =>
      item.nombre.toLowerCase().includes(value.toLowerCase())
    );
    setResultSearchEstablecimiento(result);
  };

  useEffect(() => {
    setEstablecimientoData(establecimiento.list.data);
    // eslint-disable-next-line
  }, [establecimiento]);

  const handleDelete = (data) => {
    setInversores((prev) =>
      prev.filter((item) => item.inversor_id !== data.inversor_id)
    );
  };

  const handleConfirmDelete = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  const [inversores, setInversores] = useState([]);
  const columnsInversor = [
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <DeleteFilled onClick={() => handleConfirmDelete(record)} />
        </Space>
      ),
      width: 30,
    },
    {
      // width: 100,
      ellipsis: true,
      title: 'Nombre',
      dataIndex: 'inversor_nombre',
    },
  ];

  const [inversorData, setInversorData] = useState([]);

  useEffect(() => {
    setInversorData(inversor.list.data);
    // eslint-disable-next-line
  }, [inversor]);

  const [resultSearchInversor, setResultSearchInversor] = useState([]);

  const onSearchInversor = (value) => {
    const result = inversorData.filter((item) =>
      item.nombre.toLowerCase().includes(value.toLowerCase())
    );
    setResultSearchInversor(result);
  };

  const onChangeInversor = (value) => {
    const result = inversorData.find((item) => item.id === value);

    setInversores([
      ...inversores,
      { inversor_id: result.id, inversor_nombre: result.nombre },
    ]);

    setResultSearchInversor([]);
    form.setFieldsValue({
      search_inversor_id: null,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      inversores: inversores,
    });
    // eslint-disable-next-line
  }, [inversores]);

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          mask
          initialWidth={600}
          initialHeight={600}
          maskClosable={false}
          visible={visible}
          title={
            stateData.object.data.id ? 'Editar Actividad' : 'Nueva Actividad'
          }
          okText="Guardar"
          cancelText="Salir"
          onCancel={handleOnCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Spin
            spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={stateData.object.data}
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="id" label="id" style={{ display: 'none' }}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="fecha" label="Fecha Alta">
                    <DatePicker
                      format={formatDisplay}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="superficie" label="Superficie">
                    <Input type="number" addonBefore="ha" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="establecimiento_id"
                    label="Establecimiento"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={onSearchEstablecimiento}
                      onChange={() => {}}
                      notFoundContent={null}
                      allowClear
                    >
                      {resultSearchEstablecimiento.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.nombre}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="cultivo_id"
                    label="Cultivo"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select>
                      {cultivo.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 0]} align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Card
                    title={
                      <TitleCard>
                        <span style={{ marginLeft: '3px' }}>Inversores</span>

                        <Form.Item
                          name="search_inversor_id"
                          style={{
                            marginLeft: '10px',
                            marginBottom: 0,
                            flex: 1,
                          }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={onSearchInversor}
                            onChange={onChangeInversor}
                            notFoundContent={null}
                            allowClear
                            placeholder="Buscar Inversor"
                          >
                            {resultSearchInversor.map((item, index) => (
                              <Option key={index} value={item.id}>
                                {item.nombre}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </TitleCard>
                    }
                    style={{ marginBottom: '10px' }}
                    size="small"
                    bodyStyle={
                      !inversores || inversores.length === 0
                        ? { padding: 0 }
                        : {}
                    }
                  >
                    <Row>
                      <Form.List
                        name="inversores"
                        rules={[
                          {
                            validator: async (_, inversores) => {
                              if (!inversores || inversores.length < 1) {
                                return Promise.reject(
                                  new Error('Seleccione al menos un inversor')
                                );
                              }
                            },
                          },
                        ]}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            {errors.length > 0 && (
                              <Row
                                gutter={[0, 0]}
                                style={{ width: '100%' }}
                                align="top"
                                justify="center"
                              >
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <Form.Item>
                                    <Form.ErrorList errors={errors} />
                                  </Form.Item>
                                </Col>
                              </Row>
                            )}
                          </>
                        )}
                      </Form.List>
                      {inversores && inversores.length > 0 && (
                        <MetaTable
                          dataSource={inversores}
                          dataColumns={columnsInversor}
                          bordered={false}
                          selection={false}
                          cssStyle={true}
                        />
                      )}
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Spin>
        </DraggableModal>
      </DraggableModalProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  const { actividad, establecimiento, cultivo, inversor } = state;
  const stateData = actividad;
  return {
    stateData,
    establecimiento,
    cultivo,
    inversor,
  };
};
export default connect(mapStateToProps)(ActividadEditor);
