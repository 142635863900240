import { authApi } from '../utils/api';

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_IN_PROCESS = 'LOGIN_IN_PROCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const doLogin = async (dispatch, data) => {
  dispatch(loginInProgress());

  return authApi
    .post('/login', JSON.stringify(data))
    .then(async (response) => {
      dispatch(loginSuccess(response));
    })
    .catch((error) => {
      dispatch(loginFailed(error));
    });
};

export const doLogout = async (dispatch) => {
  try {
    dispatch(loginInProgress());

    if (!localStorage.auth) {
      // throw Error('No se encuentra Logueado');
      dispatch(loginPending());
    } else {
      localStorage.removeItem('auth');
      dispatch(loginPending());
    }
  } catch (error) {
    dispatch(loginFailed(error));
  }
};

const loginPending = () => {
  return {
    type: LOGIN_PENDING,
  };
};
const loginInProgress = () => {
  return {
    type: LOGIN_IN_PROCESS,
  };
};
const loginFailed = (data) => {
  return {
    type: LOGIN_FAILED,
    data,
  };
};
const loginSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    data,
  };
};
