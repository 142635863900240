import { alquilerPropietarioApi } from '../../utils/api';
import {
  getObjectDataAction,
  createObjectDataPending,
  getListDataAction,
  saveObjectDataAction,
  deleteObjectDataAction,
  getListDeletedDataAction,
  createListDataDeletedPending,
  restoreObjectDataAction,
  getListByParentDataAction,
} from '../../utils/DataAction';

export const get = (id) =>
  getObjectDataAction({
    dataName: 'ALQUILER_PROPIETARIO',
    api: alquilerPropietarioApi,
    relUrl: id,
  });

export const getAllByParent = (id) =>
  getListByParentDataAction({
    dataName: 'ALQUILER_PROPIETARIO',
    api: alquilerPropietarioApi,
    relUrl: `indexByParent/${id}`,
  });

export const getAll = getListDataAction({
  dataName: 'ALQUILER_PROPIETARIO',
  api: alquilerPropietarioApi,
  relUrl: '',
});

export const save = (data) =>
  saveObjectDataAction({
    dataName: 'ALQUILER_PROPIETARIO',
    api: alquilerPropietarioApi,
    relUrl: '',
    data: data,
  });

export const deleted = (id) =>
  deleteObjectDataAction({
    dataName: 'ALQUILER_PROPIETARIO',
    api: alquilerPropietarioApi,
    relUrl: id,
  });

export const getAllDeleted = getListDeletedDataAction({
  dataName: 'ALQUILER_PROPIETARIO',
  api: alquilerPropietarioApi,
  relUrl: 'restore',
});

export const restore = (data) =>
  restoreObjectDataAction({
    dataName: 'ALQUILER_PROPIETARIO',
    api: alquilerPropietarioApi,
    relUrl: 'restore',
    data: data,
  });

export const setPending = createObjectDataPending('ALQUILER_PROPIETARIO');
export const setDeletedPending = createListDataDeletedPending(
  'ALQUILER_PROPIETARIO'
);
