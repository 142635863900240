import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  setDeletedPending,
  restore,
  getAllByParent,
} from '../redux/actions/alquilerPropietarioActions';
import { get as getParent } from '../redux/actions/alquilerActions';
import { useHistory } from 'react-router-dom';

import { callStatus } from '../utils/constants';
import { Button, Layout, Space } from 'antd';
import { withRouter } from 'react-router-dom';
import RestorePopup from './RestorePopup';
import MetaTable from './base/MetaTable';

const { Content, Header } = Layout;

const AlquilerPropietarioList = ({
  location,
  dispatch,
  stateData,
  // match: {
  //   params: { id },
  // },

  parentID,
  parentID2,
  parent,
}) => {
  const history = useHistory();

  const [showRestore, setShowRestore] = useState(false);

  useEffect(() => {
    dispatch(getAllByParent(parentID));
    dispatch(getParent(parentID));
    // eslint-disable-next-line
  }, []);

  const handleRestoreCancel = () => {
    setShowRestore(false);
    dispatch(setDeletedPending());
  };

  const handleRestoreOk = (data) => {
    dispatch(restore({ data: data }));
    setShowRestore(false);
  };

  const handleContratos = (data) => {
    history.push(`/campanas/alquileres/${parentID2}/contratos/${data.id}`);
  };

  const columns = [
    {
      width: 300,
      ellipsis: true,
      title: 'Propietario',
      dataIndex: 'propietario_nombre',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Superficie',
      dataIndex: 'superficie',
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={(e) => handleContratos(record)}>Contratos</Button>
        </Space>
      ),
      width: 100,
    },
  ];

  const restoreColumns = [
    {
      ellipsis: true,
      title: 'Nombre',
      dataIndex: 'nombre',
    },
  ];

  return (
    <>
      <Layout style={{ marginLeft: '100px', height: '100%' }}>
        <Header></Header>
        <Content>
          <MetaTable
            dataSource={stateData.list.data}
            dataColumns={columns}
            bordered={false}
            selection={false}
            loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
            cssStyle={true}
          />
        </Content>

        {showRestore &&
        stateData.deleted.status === callStatus.LIST_DELETED_SUCCESS ? (
          <RestorePopup
            columns={restoreColumns}
            data={stateData.deleted.data}
            onCancel={handleRestoreCancel}
            onOk={handleRestoreOk}
          />
        ) : (
          false
        )}
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  const { alquilerPropietario, alquiler } = state;
  const stateData = alquilerPropietario;
  const parent = alquiler;
  return {
    stateData,
    parent,
  };
};
export default connect(mapStateToProps)(withRouter(AlquilerPropietarioList));
