import React, { useState, useEffect } from 'react';
import OrdenTrabajoInsumoEditor from './OrdenTrabajoInsumoEditor';
import { connect } from 'react-redux';
import {
  getAllByParent,
  setPending,
  deleted,
  // setDeletedPending,
  // getAllDeleted,
  // restore,
} from '../redux/actions/ordenTrabajoInsumoActions';

import { get as getParent } from '../redux/actions/actividadActions';
import { callStatus } from '../utils/constants';
import {
  // Table,
  // Input,
  Button,
  Space,
  // Card,
  Menu,
  Dropdown,
  Row,
  Col,
  Layout,
  Modal,
} from 'antd';
import { withRouter } from 'react-router-dom';
// import Highlighter from 'react-highlight-words';
import {
  // SearchOutlined,
  EditFilled,
  DeleteFilled,
  // DownloadOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
  FormatPainterFilled,
  // SelectOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
// import RestorePopup from './RestorePopup';
import BaseBox from './base/BaseBox';
import MetaTable from './base/MetaTable';
import StateIcon from './base/StateIcon';

const { confirm } = Modal;

const { Content, Header, Footer } = Layout;

const SelectedContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

const MenuButton = styled(Button)`
  width: 100%;
  text-align: left;
`;

const OrdenTrabajoInsumoList = ({
  location,
  stateData,
  parent,
  insumo,
  inversor,
  dispatch,
  match: {
    params: { id2, id3 },
  },
  ordenTrabajoEstado,
}) => {
  const [view, setView] = useState(1);

  const [showEdit, setShowEdit] = useState(false);
  const [rowEdit, setRowEdit] = useState(null);

  useEffect(() => {
    dispatch(getAllByParent(id3));
    dispatch(getParent(id2));

    // eslint-disable-next-line
  }, []);

  const handleEdit = (data) => {
    setRowEdit(data);
    setShowEdit(true);
  };

  const handleDelete = (data) => {
    dispatch(deleted(data.id));
  };

  const handleHideEditor = () => {
    setShowEdit(false);
    setRowEdit(null);
    dispatch(setPending());
  };

  const handleConfirm = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={(e) => handleEdit(record)} />
          <DeleteFilled onClick={(e) => handleConfirm(record)} />
        </Space>
      ),
      width: 60,
    },
    {
      width: 150,
      ellipsis: true,
      title: 'Estado Pago',
      dataIndex: 'estado_nombre',
      onFilter: (value, record) => {
        return record.estado_id === value;
      },
      sorter: (a, b) => a.estado_nombre.localeCompare(b.estado_nombre),
      filterMultiple: true,
      filters: ordenTrabajoEstado.list.data.map((value) => {
        value['value'] = value.id;
        value['text'] = value.nombre;
        return value;
      }),
      render: (text) => (
        <>
          <StateIcon value={text} />
          <span> {text} </span>
        </>
      ),
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Insumo',
      dataIndex: 'insumo_nombre',
      onFilter: (value, record) => {
        return record.cultivo_id === value;
      },
      sorter: (a, b) => a.insumo_nombre.localeCompare(b.insumo_nombre),
      filterMultiple: true,
      filters: insumo.list.data.map((value) => {
        value['value'] = value.id;
        value['text'] = value.nombre;
        return value;
      }),
    },

    {
      width: 100,
      ellipsis: true,
      title: 'Dosis',
      dataIndex: 'dosis',
    },
    {
      width: 150,
      ellipsis: true,
      title: 'Total',
      dataIndex: 'total',
    },
    {
      width: 150,
      ellipsis: true,
      title: 'Precio unitario U$D',
      dataIndex: 'precio_unitario',
    },
    {
      width: 150,
      ellipsis: true,
      title: 'Importe U$D',
      dataIndex: 'importe',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Inversor',
      dataIndex: 'inversor_nombre',
      onFilter: (value, record) => {
        return record.inversor_id === value;
      },
      sorter: (a, b) => a.inversor_nombre.localeCompare(b.inversor_nombre),
      filterMultiple: true,
      filters: inversor.list.data.map((value) => {
        value['value'] = value.id;
        value['text'] = value.nombre;
        return value;
      }),
    },
  ];

  const grid = { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 };

  const menuView = (
    <Menu>
      <Menu.Item key="1">
        <MenuButton onClick={() => setView(1)} icon={<UnorderedListOutlined />}>
          Tabla
        </MenuButton>
      </Menu.Item>
      <Menu.Item key="2">
        <MenuButton onClick={() => setView(2)} icon={<UnorderedListOutlined />}>
          Box
        </MenuButton>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ height: '100%' }}>
      <Header
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginTop: '5px',
        }}
      >
        {parent.object.status === callStatus.OBJECT_SUCCESS && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <div
              style={{
                height: '17px',
              }}
            >
              <b>Campaña: </b>
              {parent.object.data.campana.nombre}
            </div>

            <div
              style={{
                height: '17px',
              }}
            >
              <b>Actividad: </b>
              {parent.object.data.establecimiento.nombre}/
              {parent.object.data.cultivo.nombre}
            </div>
          </div>
        )}
      </Header>
      <Content>
        {view === 1 ? (
          // <BaseTable
          //   columns={columns}
          //   dataSource={stateData.list.data}
          //   handleDelete={handleDelete}
          //   handleEdit={handleEdit}
          // />
          <MetaTable
            dataSource={stateData.list.data}
            dataColumns={columns}
            bordered={false}
            selection={false}
            loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
          />
        ) : (
          <Row gutter={8}>
            {stateData.list.data.map((item) => {
              return (
                <Col {...grid} key={item.id} style={{ marginBottom: '10px' }}>
                  <BaseBox
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    item={item}
                    title={item.establecimiento_nombre}
                    subtitle={item.cultivo_nombre}
                    moreInfo={item.campana}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      </Content>
      <Footer>
        <FooterContainer>
          <SelectedContainer></SelectedContainer>
          <ActionsContainer>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={(e) => handleEdit({})}
            >
              Agregar
            </Button>

            <Dropdown overlay={menuView}>
              <Button
                type="primary"
                icon={<FormatPainterFilled />}
                size="small"
                style={{ marginLeft: '3px' }}
              >
                Vista
              </Button>
            </Dropdown>
          </ActionsContainer>
        </FooterContainer>
      </Footer>
      {showEdit ? (
        <OrdenTrabajoInsumoEditor
          id={rowEdit.id}
          parentId={id3}
          onCancel={handleHideEditor}
          // onCreate={handleSaveEditor}
          visible={true}
        />
      ) : (
        false
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const {
    ordenTrabajoInsumo,
    insumo,
    inversor,
    ordenTrabajoEstado,
    actividad,
  } = state;
  const stateData = ordenTrabajoInsumo;
  const parent = actividad;
  return {
    stateData,
    actividad,
    insumo,
    inversor,
    ordenTrabajoEstado,
    parent,
  };
};
export default connect(mapStateToProps)(withRouter(OrdenTrabajoInsumoList));
