import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Row, Col } from 'antd';
import { get, save } from '../redux/actions/campanaActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';

import cloneDeep from 'lodash/cloneDeep';
import { Notificacion } from './Notificacion';
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
import { callStatus } from '../utils/constants';
const { confirm } = Modal;

const CampanaEditor = ({ dispatch, id, stateData, visible, onCancel }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    form.setFieldsValue(stateData.object.data);
    setInitial(stateData.object.data);

    // eslint-disable-next-line
  }, [stateData]);

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);

    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      form.setFieldsValue(data);
      setInitial(data);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      Notificacion('El registro se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      Notificacion(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  const onCreate = (data) => {
    dispatch(save(data));
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };
  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          mask
          initialWidth={500}
          initialHeight={250}
          maskClosable={false}
          visible={visible}
          title={stateData.object.data.id ? 'Editar Campana' : 'Nuevo Campana'}
          okText="Guardar"
          cancelText="Salir"
          onCancel={handleOnCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={stateData.object.data}
          >
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="id" label="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="nombre"
                  label="Nombre"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </DraggableModal>
      </DraggableModalProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  const { campana } = state;
  const stateData = campana;
  return {
    stateData,
  };
};
export default connect(mapStateToProps)(CampanaEditor);
