import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Row, Col, Select, Spin } from 'antd';
import { get, save } from '../redux/actions/insumoActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
import { callStatus } from '../utils/constants';

import cloneDeep from 'lodash/cloneDeep';
import { Notificacion } from './Notificacion';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
const { confirm } = Modal;
const { Option } = Select;

const InsumoEditor = ({
  dispatch,
  id,
  stateData,
  visible,
  onCancel,
  proveedor,
}) => {
  const [form] = Form.useForm();
  const [proveedorData, setProveedorData] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setProveedorData(proveedor.list.data.filter((item) => item.tipo_id === 1));
    // eslint-disable-next-line
  }, [proveedor]);

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);
    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      if (data.proveedor) {
        setResultSearchProveedor([data.proveedor]);
      }

      form.setFieldsValue(data);
      setInitial(data);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      Notificacion('El registro se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      Notificacion(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  const onCreate = (data) => {
    dispatch(save(data));
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };
  const [resultSearchProveedor, setResultSearchProveedor] = useState([]);

  const onSearchProveedor = (value) => {
    const result = proveedorData.filter((item) =>
      item.nombre.toLowerCase().includes(value.toLowerCase())
    );
    setResultSearchProveedor(result);
  };
  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          mask
          initialWidth={500}
          initialHeight={350}
          maskClosable={false}
          visible={visible}
          title={stateData.object.data.id ? 'Editar Insumo' : 'Nuevo Insumo'}
          okText="Guardar"
          cancelText="Salir"
          onCancel={handleOnCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          {' '}
          <Spin
            spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={stateData.object.data}
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="id" label="id" style={{ display: 'none' }}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="nombre"
                    label="Nombre"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item name="precio" label="Precio">
                    <Input type="number" addonBefore="U$D" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                  <Form.Item name="proveedor_id" label="Proveedor">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={onSearchProveedor}
                      onChange={() => {}}
                      notFoundContent={null}
                      allowClear
                    >
                      {resultSearchProveedor.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.nombre}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </DraggableModal>
      </DraggableModalProvider>{' '}
    </>
  );
};

const mapStateToProps = (state) => {
  const { insumo, proveedor } = state;
  const stateData = insumo;
  return {
    stateData,
    proveedor,
  };
};
export default connect(mapStateToProps)(InsumoEditor);
