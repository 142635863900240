import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Row, Col, Select, DatePicker, Spin } from 'antd';
import { get, save } from '../redux/actions/contratoActions';
import { get as getParent } from '../redux/actions/campanaActions';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
import { callStatus } from '../utils/constants';
import { Notificacion } from './Notificacion';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
const { confirm } = Modal;

const ContratoEditor = ({
  dispatch,
  id,
  parentId,
  stateData,
  visible,
  onCancel,
  formaPagoContrato,
  // numero,
  parent,
  inversor,
}) => {
  const [validateForma, setValidateForma] = useState(true);
  const [form] = Form.useForm();

  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    dispatch(getParent(parentId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);
    data['fecha'] = moment(moment(data.fecha));
    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      data['fecha'] = moment(moment(data.fecha));
      if (!id) {
        // data.numero = numero;
        data.forma_pago_id = 1;
      } else {
        if (data.forma_pago_id === 1) {
          setValidateForma(true);
        } else {
          setValidateForma(false);
        }
      }
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      Notificacion('El registro se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      Notificacion(stateData.object.error, 'error', () => {});
    }
    form.setFieldsValue(data);
    setInitial(data);
    // eslint-disable-next-line
  }, [stateData.object]);

  // useEffect(() => {
  //   let data = cloneDeep(stateData.object.data);
  //   data['fecha'] = moment(moment(data.fecha));
  //   if (!id) {
  //     // data.numero = numero;
  //     data.forma_pago_id = 1;
  //   } else {
  //     if (data.forma_pago_id === 1) {
  //       setValidateForma(true);
  //     } else {
  //       setValidateForma(false);
  //     }
  //   }

  //   form.setFieldsValue(data);
  //   setInitial(data);

  //   // eslint-disable-next-line
  // }, [stateData.object]);

  // useEffect(() => {
  //   if (!id) {
  //     // form.setFieldsValue({ superficie: parent.object.data.superficie });
  //     setInitial({
  //       ...form.getFieldValue(),
  //       // superficie: parent.object.data.superficie,
  //     });
  //     if (stateData.object.data.propietario) {
  //       setResultSearchPropietario([stateData.object.data.propietario]);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [parent]);

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);
    data.alquiler_propietario_id = parentId;
    // if (!id) {
    //   data.numero = numero;
    // }
    // console.log(data);
    dispatch(save(data));
  };

  const handleChangeForma = () => {
    calcToneladas();
    if (form.getFieldValue('forma_pago_id') === 1) {
      setValidateForma(true);
      form.setFieldsValue({
        toneladas_total: null,
        superficie: null,
        toneladas: null,
      });
    } else {
      setValidateForma(false);
      form.setFieldsValue({
        importe: null,
      });
    }
  };
  useEffect(() => {
    form.validateFields([
      'importe',
      'superficie',
      'toneladas',
      'toneladas_total',
    ]);
    // eslint-disable-next-line
  }, [validateForma]);

  const calcToneladas = () => {
    const forma = form.getFieldValue('forma_pago_id');

    const superficie = form.getFieldValue('superficie');
    const toneladas = form.getFieldValue('toneladas');

    if (forma === 2 && superficie && toneladas) {
      const toneladas_total = superficie * toneladas;
      form.setFieldsValue({ toneladas_total: toneladas_total.toFixed(2) });
    }
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          mask
          initialWidth={500}
          initialHeight={500}
          maskClosable={false}
          visible={visible}
          title={
            stateData.object.data.id ? 'Editar Contrato' : 'Nuevo Contrato'
          }
          okText="Guardar"
          cancelText="Salir"
          onCancel={handleOnCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Spin
            spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={stateData.object.data}
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="id" label="id" style={{ display: 'none' }}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="inversor_id"
                    label="Inversor"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select allowClear>
                      {inversor.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="fecha"
                    label="Fecha"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <DatePicker
                      format={formatDisplay}
                      style={{ width: '100%' }}
                      initialValue={moment(new Date())}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="forma_pago_id"
                    label="Modalidad Pago"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select onChange={handleChangeForma}>
                      {formaPagoContrato.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={
                    validateForma ? { display: 'none' } : { display: 'block' }
                  }
                >
                  <Form.Item
                    name="superficie"
                    label="Superficie"
                    rules={[
                      {
                        required: !validateForma,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      addonBefore="Ha"
                      onBlur={calcToneladas}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={
                    validateForma ? { display: 'none' } : { display: 'block' }
                  }
                >
                  <Form.Item
                    name="toneladas"
                    label="Toneladas x HA"
                    rules={[
                      {
                        required: !validateForma,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input type="number" onBlur={calcToneladas} />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={
                    validateForma ? { display: 'none' } : { display: 'block' }
                  }
                >
                  <Form.Item
                    name="toneladas_total"
                    label="Toneladas Totales"
                    rules={[
                      {
                        required: !validateForma,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input type="number" onBlur={calcToneladas} />
                  </Form.Item>
                </Col>

                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={
                    !validateForma ? { display: 'none' } : { display: 'block' }
                  }
                >
                  <Form.Item
                    name="importe"
                    label="Importe"
                    rules={[
                      {
                        required: validateForma,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input addonBefore="U$D" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </DraggableModal>
      </DraggableModalProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  const { contrato, inversor, formaPagoContrato, alquilerPropietario } = state;
  const stateData = contrato;
  const parent = alquilerPropietario;
  return {
    stateData,
    inversor,
    formaPagoContrato,
    parent,
  };
};
export default connect(mapStateToProps)(ContratoEditor);
