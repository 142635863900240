import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Row, Col, DatePicker, Spin } from 'antd';
import { getByParent, save } from '../redux/actions/produccionActions';

import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
import { callStatus } from '../utils/constants';
import { Notificacion } from './Notificacion';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
const { confirm, error } = Modal;

const ProduccionEditor = ({
  dispatch,
  id,
  parentId,
  stateData,
  visible,
  onCancel,
  proveedor,
  labor,
  inversor,
  produccionTipo,
}) => {
  const [form] = Form.useForm();

  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';

  useEffect(() => {
    if (parentId) {
      dispatch(getByParent(parentId));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!id) {
      let data = {};
      data['fecha'] = moment(moment(data.fecha));

      form.setFieldsValue(data);
      setInitial(data);
    }

    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      let data = cloneDeep(stateData.object.data);
      data['fecha'] = moment(moment(data.fecha));

      form.setFieldsValue(data);
      setInitial(data);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      Notificacion('El registro se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      Notificacion(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  const handleSaveError = (message) => {
    error({
      title: message,
      onOk() {},
    });
  };

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);
    data.actividad_id = parentId;

    if (!data.cantidad) {
      handleSaveError(
        'La Cantidad producida debe ser mayor a 0, debe cargar Retiros'
      );
    } else {
      dispatch(save(data)).then((response) => {
        onCancel();
      });
    }
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  // const calcImporte = () => {
  //   const superficie = form.getFieldValue('superficie');
  //   // const precio = form.getFieldValue('precio');
  //   const dolar = form.getFieldValue('dolar');

  //   if (superficie && dolar) {
  //     const importe = superficie * dolar;
  //     form.setFieldsValue({ importe: importe.toFixed(2) });
  //   }
  // };

  const calcTotales = () => {
    const superficie = parseFloat(form.getFieldValue('superficie'));
    const cantidad = parseFloat(form.getFieldValue('cantidad'));
    const precio = parseFloat(form.getFieldValue('precio'));
    const precio_extra = parseFloat(form.getFieldValue('precio_extra'));
    const bonificacion = parseFloat(form.getFieldValue('bonificacion'));
    const comercializacion_porcentaje = parseFloat(
      form.getFieldValue('comercializacion_porcentaje')
    );

    let rinde = null;
    if (cantidad && superficie) {
      rinde = cantidad / superficie;
      form.setFieldsValue({ rinde: rinde.toFixed(2) });
    }

    let importe = null;
    if (cantidad && precio) {
      importe = cantidad * (precio + precio_extra);
      form.setFieldsValue({ importe: importe.toFixed(2) });
    }

    let total = null;
    if (cantidad && precio && importe && bonificacion) {
      total = importe + (cantidad * precio * bonificacion) / 100;
      form.setFieldsValue({ total: total.toFixed(2) });
    }

    let comercializacion = null;
    if (total && comercializacion_porcentaje) {
      comercializacion = (total * comercializacion_porcentaje) / 100;
      form.setFieldsValue({ comercializacion: comercializacion.toFixed(2) });
    }
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          mask
          initialWidth={700}
          initialHeight={700}
          maskClosable={false}
          visible={visible}
          title={
            stateData.object.data.id ? 'Editar Produccion' : 'Nueva Produccion'
          }
          okText="Guardar"
          cancelText="Salir"
          onCancel={handleOnCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Spin
            spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={stateData.object.data}
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="id" label="id" style={{ display: 'none' }}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="superficie" label="Superficie">
                    <Input type="number" addonBefore="ha" readOnly />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="fecha"
                    label="Fecha"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <DatePicker
                      format={formatDisplay}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="cantidad" label="Cantidad">
                    <Input type="number" addonBefore="tn" readOnly />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="precio"
                    label="Precio"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      addonBefore="U$D"
                      onBlur={calcTotales}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="precio_extra"
                    label="Precio extra"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      addonBefore="U$D"
                      onBlur={calcTotales}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="importe" label="Importe">
                    <Input type="number" addonBefore="U$D" readOnly />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="rinde" label="Rinde">
                    <Input type="number" addonBefore="tn" readOnly />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="bonificacion"
                    label="Bonificación"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input type="number" addonBefore="%" onBlur={calcTotales} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="total" label="Total">
                    <Input type="number" addonBefore="U$D" readOnly />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Form.Item
                    name="comercializacion_porcentaje"
                    label="Comercialización"
                  >
                    <Input type="number" addonBefore="%" onBlur={calcTotales} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="comercializacion" label="Comercialización">
                    <Input type="number" addonBefore="U$D" readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </DraggableModal>
      </DraggableModalProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  const { produccion } = state;
  const stateData = produccion;
  return {
    stateData,
  };
};
export default connect(mapStateToProps)(ProduccionEditor);
