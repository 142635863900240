import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Spin,
  Button,
  Select,
} from 'antd';
import { getByParent, save } from '../redux/actions/gananciaActions';

import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
import { callStatus } from '../utils/constants';
import { Notificacion } from './Notificacion';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
import { campanaApi } from '../utils/api';
import TextArea from 'antd/lib/input/TextArea';
const { confirm, error } = Modal;

const GananciaEditor = ({
  dispatch,
  id,
  parentId,
  stateData,
  inversor,
  visible,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [calcular, setCalcular] = useState(false);

  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';

  const handleGetGananciaInversor = () => {
    let data = {
      labores: null,
      insumos: null,
      importe: null,
      total: null,
      descripcion: null,
      dolar: null,
    };

    form.setFieldsValue(data);
    dispatch(getByParent(parentId, form.getFieldValue('inversor_id')));
  };

  useEffect(() => {
    if (!id) {
      let data = {};
      data['fecha'] = moment(moment(data.fecha));

      form.setFieldsValue(data);
      setInitial(data);
    }

    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      let data = cloneDeep(stateData.object.data);
      data['fecha'] = moment(moment(data.fecha));

      form.setFieldsValue(data);
      setInitial(data);

      if (!data.id) {
        setCalcular(true);
      }
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      Notificacion('El registro se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      Notificacion(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  const getGanancia = () => {
    if (
      !form.getFieldValue('anio') ||
      !form.getFieldValue('inversor_id') ||
      !form.getFieldValue('dolar')
    ) {
      handleSaveError(
        'Debe seleccionar Inversor, Año y Cotización del Dolar para realizar el cálculo'
      );
    } else {
      setLoading(true);
      campanaApi
        .get(
          `/ganancia/${parentId}/${form.getFieldValue(
            'anio'
          )}/${form.getFieldValue('inversor_id')}`
        )
        .then((response) => {
          let data = {};
          data['fecha'] = moment(moment(undefined));
          data['importe'] = response.data.importe;
          data['labores'] = response.data.labores;
          data['insumos'] = response.data.insumos;
          data['descripcion'] = convertDescripcion(response.data.cultivos);
          form.setFieldsValue(data);
          setInitial(data);
          setLoading(false);
          setCalcular(false);

          calcTotales();
        })
        .catch((error) => {
          console.log(error);
          Notificacion(error, 'error', () => {});
          setLoading(false);
        });
    }
  };

  const convertDescripcion = (cultivos) => {
    let descripcion = '';
    cultivos.forEach((item) => {
      const dolar = parseFloat(form.getFieldValue('dolar'));

      const importeInsumos = item.insumos;
      const totalInsumos = importeInsumos * dolar;

      const importeLabores = item.labores;
      const totalLabores = importeLabores * dolar;

      descripcion =
        descripcion +
        item.cultivo.nombre +
        '\n' +
        'Insumos: ' +
        totalInsumos +
        '\n' +
        'Labores: ' +
        totalLabores +
        '\n\n';
    });

    return descripcion;
  };

  const handleSaveError = (message) => {
    error({
      title: message,
      onOk() {},
    });
  };

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);
    data.campana_id = parentId;

    if (calcular) {
      handleSaveError('Debe realizar el cálculo de ganancia');
    } else {
      if (!data.total || parseFloat(data.total) === 0) {
        handleSaveError('No existe calculo de ganancia para el Inversor');
      } else {
        dispatch(save(data)).then((response) => {
          onCancel();
        });
      }
    }
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  const calcTotales = () => {
    if (form.getFieldValue('importe')) {
      const importe = parseFloat(form.getFieldValue('importe'));
      const dolar = parseFloat(form.getFieldValue('dolar'));
      const total = importe * dolar;
      form.setFieldsValue({ total: total.toFixed(2) });
    }
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          mask
          initialWidth={700}
          initialHeight={700}
          maskClosable={false}
          visible={visible}
          title={
            stateData.object.data.id
              ? 'Editar Total de Gastos para Ganancia'
              : 'Nuevo Total de Gastos para Ganancia'
          }
          okText="Guardar"
          cancelText="Salir"
          onCancel={handleOnCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Spin
            spinning={
              loading ||
              stateData.object.status === callStatus.OBJECT_IN_PROCESS
            }
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={stateData.object.data}
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="id" label="id" style={{ display: 'none' }}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                  <Form.Item
                    name="fecha"
                    label="Fecha"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <DatePicker
                      format={formatDisplay}
                      style={{ width: '100%' }}
                      disabled={calcular}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                  <Form.Item
                    name="inversor_id"
                    label="Inversor"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select allowClear onChange={handleGetGananciaInversor}>
                      {inversor.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                  <Form.Item
                    name="anio"
                    label="Año"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                  <Form.Item name="calcular" label=" ">
                    <Button
                      type="primary"
                      onClick={(e) => getGanancia()}
                      style={{ width: '100%' }}
                    >
                      {stateData.object.data.id ? 'Recalcular' : 'Calcular'}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item name="labores" label="Total labores">
                    <Input
                      type="number"
                      addonBefore="U$D"
                      readOnly
                      disabled={calcular}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item name="insumos" label="Total insumos">
                    <Input
                      type="number"
                      addonBefore="U$D"
                      readOnly
                      disabled={calcular}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item name="importe" label="Total gastos">
                    <Input
                      type="number"
                      addonBefore="U$D"
                      readOnly
                      disabled={calcular}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="dolar"
                    label="Dolar"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      addonBefore="U$D"
                      onBlur={calcTotales}
                      // disabled={calcular}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="total" label="Total">
                    <Input
                      type="number"
                      addonBefore="$"
                      readOnly
                      disabled={calcular}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="descripcion" label="Detalle Cultivos">
                    <TextArea readOnly rows={8} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </DraggableModal>
      </DraggableModalProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  const { ganancia, inversor } = state;
  const stateData = ganancia;
  return {
    inversor,
    stateData,
  };
};
export default connect(mapStateToProps)(GananciaEditor);
