import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Row, Col, Select, DatePicker, Spin } from 'antd';
import { get, save } from '../redux/actions/contratoCuotaActions';
// import { get as getParent } from '../redux/actions/contratoActions';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
import { callStatus } from '../utils/constants';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
import { Notificacion } from './Notificacion';
const { confirm } = Modal;
const ContratoCuotaEditor = ({
  dispatch,
  id,
  parentId,
  stateData,
  visible,
  onCancel,
  inversor,
  numero,
  parent,
  contratoCuotaEstado,
}) => {
  const [form] = Form.useForm();

  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // dispatch(getParent(parentId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);
    data['fecha'] = moment(moment(data.fecha));
    if (!id) {
      data.numero = numero;
      data['estado_id'] = 1;
      form.setFieldsValue(data);
      setInitial(data);
    }
    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      data['fecha'] = moment(moment(data.fecha));
      data['total_final'] = (
        parseFloat(data.importe) + parseFloat(data.comision)
      ).toFixed(2);
      form.setFieldsValue(data);
      setInitial(data);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      Notificacion('El registro se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      Notificacion(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  // useEffect(() => {
  //   let data = cloneDeep(stateData.object.data);
  //   data['fecha'] = moment(moment(data.fecha));
  //   if (!id) {
  //     data.numero = numero;
  //     data['estado_id'] = 1;
  //   }
  //   form.setFieldsValue(data);
  //   setInitial(data);

  //   // eslint-disable-next-line
  // }, [stateData]);

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);
    data.contrato_id = parentId;
    if (!id) {
      data.numero = numero;
    }

    if ((data.estado_id === 3 || data.estado_id === 4) && !data.importe) {
      Notificacion(
        'Para Finalizar o Simular debe completar Importe',
        'error',
        () => {}
      );
    } else {
      dispatch(save(data));
    }
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  const calcImporte = () => {
    const forma = parent.object.data.forma_pago_id;

    const toneladas = form.getFieldValue('toneladas');
    const precio = form.getFieldValue('precio');
    const dolar = form.getFieldValue('dolar');

    if (forma === 2 && toneladas && precio && dolar) {
      const importe = (toneladas * precio) / dolar;
      form.setFieldsValue({ importe: importe.toFixed(2) });
    }

    if (forma === 1 && precio && dolar) {
      const importe = precio / dolar;
      form.setFieldsValue({ importe: importe.toFixed(2) });
    }

    calcComision();
  };

  const calcComision = () => {
    const porcentaje = form.getFieldValue('porcentaje_comision');
    const importe = form.getFieldValue('importe');

    if (importe && porcentaje) {
      const comision = (importe * porcentaje) / 100;

      const total = comision + parseFloat(importe);
      form.setFieldsValue({
        comision: comision.toFixed(2),
        total_final: total.toFixed(2),
      });
    } else {
      form.setFieldsValue({
        comision: null,
        total_final: null,
      });
    }
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          mask
          initialWidth={600}
          initialHeight={600}
          maskClosable={false}
          visible={visible}
          title={`${
            stateData.object.data.id ? 'Editar Cuota' : 'Nueva Cuota'
          } ${parent.object.data.forma_pago_nombre}`}
          okText="Guardar"
          cancelText="Salir"
          onCancel={handleOnCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Spin
            spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={stateData.object.data}
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="id" label="id" style={{ display: 'none' }}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item name="numero" label="Número">
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item
                    name="fecha"
                    label="Fecha"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <DatePicker
                      format={formatDisplay}
                      style={{ width: '100%' }}
                      initialValue={moment(new Date())}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item
                    name="estado_id"
                    label="Estado"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select>
                      {contratoCuotaEstado.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={
                    parent.object.data.forma_pago_id === 1
                      ? { display: 'none' }
                      : { display: 'block' }
                  }
                >
                  <Form.Item
                    name="toneladas"
                    label="Toneladas"
                    rules={[
                      {
                        required:
                          parent.object.data.forma_pago_id === 2 ? true : false,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input type="number" onBlur={calcImporte} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="precio"
                    label={
                      parent.object.data.forma_pago_id === 1
                        ? 'Precio Cuota'
                        : 'Precio Soja'
                    }
                    rules={[
                      {
                        required:
                          parent.object.data.forma_pago_id === 1 ? true : false,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input type="number" addonBefore="$" onBlur={calcImporte} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="dolar" label="Dolar">
                    <Input
                      type="number"
                      addonBefore="U$D"
                      onBlur={calcImporte}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="importe" label="Importe Total">
                    <Input addonBefore="U$D" readOnly />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="porcentaje_comision"
                    label="Porcentaje Comisión"
                  >
                    <Input
                      type="number"
                      addonBefore="%"
                      onBlur={calcComision}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="comision" label="Comisión Total">
                    <Input addonBefore="U$D" readOnly />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="total_final" label="Total Final">
                    <Input addonBefore="U$D" readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </DraggableModal>
      </DraggableModalProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  const { contratoCuota, inversor, contrato, contratoCuotaEstado } = state;
  const stateData = contratoCuota;
  const parent = contrato;

  return {
    stateData,
    inversor,
    parent,
    contratoCuotaEstado,
  };
};
export default connect(mapStateToProps)(ContratoCuotaEditor);
