import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import {
  cultivoReducer,
  insumoReducer,
  laborReducer,
  inversorReducer,
  establecimientoReducer,
  propietarioReducer,
  actividadReducer,
  contratoReducer,
  contratoCuotaReducer,
  contratoCuotaEstadoReducer,
  actividadInversorReducer,
  ordenTrabajoReducer,
  ordenTrabajoEstadoReducer,
  ordenTrabajoInsumoReducer,
  proveedorReducer,
  proveedorTipoReducer,
  formaPagoContratoReducer,
  condicionIvaReducer,
  configuracionReducer,
  campanaReducer,
  alquilerReducer,
  alquilerPropietarioReducer,
  gastoReducer,
  gastoTipoReducer,
  produccionRetiroReducer,
  produccionReducer,
  destinoReducer,
  cacheReducer,
  monedaReducer,
  multiplicadorReducer,
  gananciaReducer,
} from './dataReducers';
import AuthReducer from './AuthReducer';

export const rootReducer = combineReducers({
  auth: AuthReducer,
  cultivo: cultivoReducer,
  insumo: insumoReducer,
  labor: laborReducer,
  inversor: inversorReducer,
  establecimiento: establecimientoReducer,
  propietario: propietarioReducer,
  actividad: actividadReducer,
  contrato: contratoReducer,
  contratoCuota: contratoCuotaReducer,
  contratoCuotaEstado: contratoCuotaEstadoReducer,
  formaPagoContrato: formaPagoContratoReducer,
  actividadInversor: actividadInversorReducer,
  ordenTrabajo: ordenTrabajoReducer,
  ordenTrabajoEstado: ordenTrabajoEstadoReducer,
  ordenTrabajoInsumo: ordenTrabajoInsumoReducer,
  proveedor: proveedorReducer,
  proveedorTipo: proveedorTipoReducer,
  condicionIva: condicionIvaReducer,
  configuracion: configuracionReducer,
  campana: campanaReducer,
  alquiler: alquilerReducer,
  alquilerPropietario: alquilerPropietarioReducer,
  gasto: gastoReducer,
  gastoTipo: gastoTipoReducer,
  produccionRetiro: produccionRetiroReducer,
  destino: destinoReducer,
  produccion: produccionReducer,
  ganancia: gananciaReducer,
  cache: cacheReducer,
  moneda: monedaReducer,
  multiplicador: multiplicadorReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
