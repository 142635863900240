import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Row, Col, Select, Spin } from 'antd';
import { get, save } from '../redux/actions/ordenTrabajoInsumoActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
import { callStatus } from '../utils/constants';
import cloneDeep from 'lodash/cloneDeep';
import { Notificacion } from './Notificacion';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
const { confirm } = Modal;
const { Option } = Select;
const OrdenTrabajoInsumoEditor = ({
  dispatch,
  id,
  parentId,
  stateData,
  visible,
  onCancel,
  insumo,
  inversor,
  ordenTrabajoEstado,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);
    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      form.setFieldsValue(data);
      setInitial(data);
      if (data.insumo) {
        setResultSearchInsumo([data.insumo]);
      }
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      Notificacion('El registro se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      Notificacion(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  const onCreate = (data) => {
    data.orden_trabajo_id = parentId;

    if (
      ((data.estado_id === 3 || data.estado_id === 4) && !data.importe) ||
      !data.inversor_id
    ) {
      Notificacion(
        'Para Finalizar o Simular debe completar Importe e Inversor ',
        'error',
        () => {}
      );
    } else {
      dispatch(save(data));
    }
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };
  const [resultSearchInsumo, setResultSearchInsumo] = useState([]);

  const onSearchInsumo = (value) => {
    const result = insumo.list.data.filter((item) =>
      item.nombre.toLowerCase().includes(value.toLowerCase())
    );
    setResultSearchInsumo(result);
  };

  const handleChangeInsumo = (value) => {
    const ins = insumo.list.data.find((item) => item.id === value);
    if (ins) {
      form.setFieldsValue({ precio_unitario: ins.precio });
    }
  };

  const calcImporte = () => {
    const total = form.getFieldValue('total');
    const precio_unitario = form.getFieldValue('precio_unitario');

    if (total && precio_unitario) {
      const importe = total * precio_unitario;
      form.setFieldsValue({ importe: importe.toFixed(2) });
    }
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          mask
          initialWidth={600}
          initialHeight={600}
          maskClosable={false}
          visible={visible}
          title={stateData.object.data.id ? 'Editar Insumo' : 'Nuevo Insumo'}
          okText="Guardar"
          cancelText="Salir"
          onCancel={handleOnCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Spin
            spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={stateData.object.data}
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="id" label="id" style={{ display: 'none' }}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                  <Form.Item
                    name="insumo_id"
                    label="Insumo"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={onSearchInsumo}
                      onChange={handleChangeInsumo}
                      notFoundContent={null}
                      allowClear
                    >
                      {resultSearchInsumo.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.nombre}{' '}
                          {item.proveedor && `- (${item.proveedor.nombre})`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item
                    name="estado_id"
                    label="Estado"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select>
                      {ordenTrabajoEstado.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="dosis" label="Dosis por hectarea">
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="total"
                    label="Total"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input onBlur={calcImporte} />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="precio_unitario" label="Precio Unitario">
                    <Input
                      type="number"
                      addonBefore="U$D"
                      onBlur={calcImporte}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="importe" label="Importe">
                    <Input type="number" addonBefore="U$D" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="inversor_id" label="Inversor">
                    <Select allowClear>
                      {inversor.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </DraggableModal>
      </DraggableModalProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  const { ordenTrabajoInsumo, insumo, inversor, ordenTrabajoEstado } = state;
  const stateData = ordenTrabajoInsumo;
  return {
    stateData,
    insumo,
    inversor,
    ordenTrabajoEstado,
  };
};
export default connect(mapStateToProps)(OrdenTrabajoInsumoEditor);
