import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Row, Col, Select, Spin, Space, Card } from 'antd';
import { get, save } from '../redux/actions/establecimientoActions';
import {
  ExclamationCircleOutlined,
  DeleteFilled,
  // PlusCircleOutlined,
} from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
import { callStatus } from '../utils/constants';
import styled from 'styled-components';
import MetaTable from './base/MetaTable';
import cloneDeep from 'lodash/cloneDeep';
import { Notificacion } from './Notificacion';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
const { Option } = Select;

const { confirm } = Modal;

const TitleCard = styled.div`
  display: flex;
  align-items: center;
`;

const EstablecimientoEditor = ({
  dispatch,
  id,
  stateData,
  visible,
  onCancel,
  propietario,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    form.setFieldsValue(stateData.object.data);
    setInitial(stateData.object.data);
    if (stateData.object.data.propietarios) {
      setPropietarios(stateData.object.data.propietarios);
    }

    // eslint-disable-next-line
  }, [stateData]);

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);
    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      if (data.propietarios) {
        setPropietarios(data.propietarios);
      }

      form.setFieldsValue(data);
      setInitial(data);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      Notificacion('El registro se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      Notificacion(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  const onCreate = (data) => {
    dispatch(save(data));
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  const handleDelete = (data) => {
    // dispatch(deleted(data.id));
    setPropietarios((prev) =>
      prev.filter((item) => item.propietario_id !== data.propietario_id)
    );
  };

  const handleConfirmDelete = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  const onSuperficiePropietarioChange = (e, key, index, record) => {
    let newData = [...propietarios];
    newData = [
      ...newData.map((item) => {
        if (item.propietario_id === record.propietario_id) {
          return { ...item, superficie: e.target.value };
        } else {
          return item;
        }
      }),
    ];

    setPropietarios(newData);
  };

  const [propietarios, setPropietarios] = useState([]);
  const columnsPropietario = [
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <DeleteFilled onClick={() => handleConfirmDelete(record)} />
        </Space>
      ),
      width: 30,
    },
    {
      // width: 100,
      ellipsis: true,
      title: 'Nombre',
      dataIndex: 'propietario_nombre',
    },
    {
      width: 150,
      title: 'Superficie',
      dataIndex: 'superficie',
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) =>
            onSuperficiePropietarioChange(e, 'superficie', index, record)
          }
        />
      ),
    },
  ];

  const [propietarioData, setPropietarioData] = useState([]);

  useEffect(() => {
    setPropietarioData(propietario.list.data);
    // eslint-disable-next-line
  }, [propietario]);

  const [resultSearchPropietario, setResultSearchPropietario] = useState([]);

  const onSearchPropietario = (value) => {
    const result = propietarioData.filter((item) =>
      item.nombre.toLowerCase().includes(value.toLowerCase())
    );
    setResultSearchPropietario(result);
  };

  const onChangePropietario = (value) => {
    const result = propietarioData.find((item) => item.id === value);

    setPropietarios([
      ...propietarios,
      { propietario_id: result.id, propietario_nombre: result.nombre },
    ]);

    setResultSearchPropietario([]);
    form.setFieldsValue({
      search_propietario_id: null,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      propietarios: propietarios,
    });
    // eslint-disable-next-line
  }, [propietarios]);

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          mask
          initialWidth={500}
          initialHeight={500}
          maskClosable={false}
          visible={visible}
          title={
            stateData.object.data.id
              ? 'Editar Establecimiento'
              : 'Nuevo Establecimiento'
          }
          okText="Guardar"
          cancelText="Salir"
          onCancel={handleOnCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Spin
            spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={stateData.object.data}
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="id" label="id" style={{ display: 'none' }}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="propietarios"
                    label="propietarios"
                    style={{ display: 'none' }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="nombre"
                    label="Nombre"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="propietario_id"
                  label="Propietario"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Select>
                    {propietario.list.data.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col> */}

                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                  <Form.Item name="superficie" label="Superficie">
                    <Input type="number" addonBefore="ha" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                  <Form.Item name="ubicacion" label="Ubicación">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 0]} align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Card
                    title={
                      <TitleCard>
                        <span style={{ marginLeft: '3px' }}>Propietarios</span>

                        <Form.Item
                          name="search_propietario_id"
                          style={{
                            marginLeft: '10px',
                            marginBottom: 0,
                            flex: 1,
                          }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={onSearchPropietario}
                            onChange={onChangePropietario}
                            notFoundContent={null}
                            allowClear
                            placeholder="Buscar Propietario"
                          >
                            {resultSearchPropietario.map((item, index) => (
                              <Option key={index} value={item.id}>
                                {item.nombre}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </TitleCard>
                    }
                    style={{ marginBottom: '10px' }}
                    size="small"
                    bodyStyle={
                      !propietarios || propietarios.length === 0
                        ? { padding: 0 }
                        : {}
                    }
                  >
                    <Row>
                      <Form.List
                        name="propietarios"
                        rules={[
                          {
                            validator: async (_, propietarios) => {
                              if (!propietarios || propietarios.length < 1) {
                                return Promise.reject(
                                  new Error(
                                    'Seleccione al menos un propietario'
                                  )
                                );
                              }
                            },
                          },
                        ]}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            {errors.length > 0 && (
                              <Row
                                gutter={[0, 0]}
                                style={{ width: '100%' }}
                                align="top"
                                justify="center"
                              >
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <Form.Item>
                                    <Form.ErrorList errors={errors} />
                                  </Form.Item>
                                </Col>
                              </Row>
                            )}
                          </>
                        )}
                      </Form.List>
                      {propietarios && propietarios.length > 0 && (
                        <MetaTable
                          dataSource={propietarios}
                          dataColumns={columnsPropietario}
                          bordered={false}
                          selection={false}
                          cssStyle={true}
                        />
                      )}
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Spin>
        </DraggableModal>
      </DraggableModalProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  const { establecimiento, propietario } = state;
  const stateData = establecimiento;
  return {
    stateData,
    propietario,
  };
};
export default connect(mapStateToProps)(EstablecimientoEditor);
