import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
import { Table } from 'antd';
import { actividadApi } from '../utils/api';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const Container2 = styled.div`
  position: absolute;
  width: 100%;
  overflow: auto;
  height: 100%;
  left: 0;
`;

const PropietariosAsociadosPopup = ({
  actividades,
  onCancel,
  onOk,
  itemSelected,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (actividades) {
      actividadApi
        .post(`/asociadoPropietarios`, { data: actividades })
        .then((response) => {
          if (itemSelected && itemSelected.length > 0) {
            const result = response.data.map((value) => {
              if (
                itemSelected.some(
                  (item) => value.id === item.propietario_id && !item.deleted
                )
              ) {
                return false;
              } else {
                return {
                  ...value,
                  // propietario_id: value.id,
                  id: null,
                  deleted: false,
                };
              }
            });

            setData(result.filter((item) => item));
          } else {
            setData(
              response.data.map((value) => {
                return {
                  ...value,
                  // propietario_id: value.id,
                  id: null,
                  deleted: false,
                };
              })
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // eslint-disable-next-line
  }, [actividades]);

  const onSelectChange = (selectedRowKeys) => {
    const result = data.filter((item) =>
      selectedRowKeys.some((value) => value === item.propietario_id)
    );

    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(result);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  data.forEach((value) => {
    value['key'] = value.propietario_id;
  });

  const columns = [
    {
      ellipsis: true,
      title: 'Nombre',
      dataIndex: 'nombre',
    },
    {
      ellipsis: true,
      title: 'Superficie',
      dataIndex: 'superficie',
    },
  ];

  return (
    <DraggableModalProvider>
      <DraggableModal
        visible={true}
        okText="Guardar"
        cancelText="Salir"
        onCancel={(e) => onCancel()}
        onOk={(e) => onOk(selectedRows)}
        title="Propietarios"
        initialWidth={450}
        initialHeight={450}
      >
        <Container>
          <Container2>
            <Table
              tableLayout="auto"
              size="small"
              columns={columns}
              dataSource={data}
              pagination={false}
              rowSelection={rowSelection}
            />
          </Container2>
        </Container>
      </DraggableModal>
    </DraggableModalProvider>
  );
};

export default PropietariosAsociadosPopup;
