import React from 'react';
import { Link } from 'react-router-dom';
import routes from './routes';
import { connect } from 'react-redux';

import styled, { createGlobalStyle } from 'styled-components';
import { Layout, Menu, Button, Drawer } from 'antd';
import {
  AppstoreOutlined,
  LogoutOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { doLogout } from './redux/AuthActions';

const { Header, Content } = Layout;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderTitle = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const LayoutStyle = createGlobalStyle`

.ant-layout-header{
  background:#f0f2f5;
  padding: 0;
  height: auto;
  line-height: 0;
}

.ant-layout-content{
  background:#f0f2f5;
  padding: 0;
}

.ant-layout-footer{
  background:#f0f2f5;
  padding: 0;
  height: auto;
}

.ant-table.ant-table-small
  .ant-table-tbody
  .ant-table-wrapper:only-child
  .ant-table {
  margin: 0;
}

`;

// const ListStyle = createGlobalStyle`

//   [role=presentation] {
//     height:100%
//   }

//   .ant-table-wrapper {
//     height: 100%;

//     .ant-spin-nested-loading {
//       height: 100%;

//       .ant-spin-container {
//         height: 100%;
//         display: flex;
//         flex-flow: column nowrap;

//         .ant-table {
//           flex: 1;
//           overflow: hidden;
//           .ant-table-container {

//             height: 100%;
//             display: flex;
//             flex-flow: column nowrap;

//             .ant-table-content{
//               height:100%;
//               overflow:auto;

//               .ant-table-thead {
//                 flex: none;
//               }

//               .ant-table-tbody {
//                 flex: auto;
//                 overflow: auto;
//               }
//             }

//           }
//         }
//         .ant-table-pagination.ant-pagination {
//           margin: 7px 0;
//         }
//       }
//     }
//   }
// `;

const HeaderIcons = styled.div``;
const Navbar = ({ dispatch }) => {
  const [visible, setVisible] = React.useState(false);

  const logout = () => {
    doLogout(dispatch);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      {/* <ListStyle /> */}
      <LayoutStyle />

      <Drawer
        title={null}
        placement="left"
        closable={true}
        onClose={(e) => onClose()}
        visible={visible}
        getContainer={false}
        style={{ position: 'absolute' }}
        bodyStyle={{ padding: 0 }}
      >
        <div style={{ padding: '20px 20px 0px 20px' }}>
          <img style={{ width: '100%' }} src={'logo.png'} alt="Logo" />
        </div>
        <Menu mode="inline">
          <Menu.Item key="1" icon={<AppstoreOutlined />}>
            <Link to="" onClick={(e) => onClose()}>
              Dashboard
            </Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<AppstoreOutlined />}>
            <Link to="/establecimientos" onClick={(e) => onClose()}>
              Establecimientos
            </Link>
          </Menu.Item>
          <Menu.Item key="9" icon={<AppstoreOutlined />}>
            <Link to="/propietarios" onClick={(e) => onClose()}>
              Propietarios
            </Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<AppstoreOutlined />}>
            <Link to="/cultivos" onClick={(e) => onClose()}>
              Cultivos
            </Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<AppstoreOutlined />}>
            <Link to="/insumos" onClick={(e) => onClose()}>
              Insumos
            </Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<AppstoreOutlined />}>
            <Link to="/labores" onClick={(e) => onClose()}>
              Labores
            </Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<AppstoreOutlined />}>
            <Link to="/inversores" onClick={(e) => onClose()}>
              Inversores
            </Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<AppstoreOutlined />}>
            <Link to="/proveedores" onClick={(e) => onClose()}>
              Proveedores
            </Link>
          </Menu.Item>
          <Menu.Item key="8" icon={<AppstoreOutlined />}>
            <Link to="/campanas" onClick={(e) => onClose()}>
              Campañas
            </Link>
          </Menu.Item>
        </Menu>
      </Drawer>

      <Layout style={{ height: '100vh' }}>
        <Header>
          <HeaderContainer className="site-layout-sub-header-background">
            <Button
              type="primary"
              icon={<MenuOutlined />}
              size="small"
              onClick={(e) => setVisible(!visible)}
            />

            <HeaderTitle>La Lluvia Agro</HeaderTitle>
            <HeaderIcons>
              <LogoutOutlined onClick={() => logout()} />
            </HeaderIcons>
          </HeaderContainer>
        </Header>
        <Content style={{ margin: '15px' }}>{routes}</Content>
      </Layout>
    </>
  );
};
export default connect()(Navbar);
