import { actividadApi } from './api';
import dayjs from 'dayjs';
import moment from 'moment';
const formatDisplay = 'DD/MM/YYYY';

export const compareObject = (initial, form) => {
  let changes = {};

  let initialData = { ...initial };
  delete initialData.created_at;
  delete initialData.updated_at;
  delete initialData.deleted;
  // console.log('initial', initialData);
  // console.log('form', form);

  Object.entries(form)
    .filter(
      ([key, value]) =>
        !Array.isArray(value) &&
        typeof value !== 'object' &&
        value !== undefined
    )
    .forEach(([key, value]) => {
      if (!initialData.hasOwnProperty(key) || initialData[key] !== value) {
        changes[key] = value;
      }
    });
  // console.log(changes);

  if (Object.values(changes).length > 0) {
    return true;
  } else {
    return false;
  }
};

export const convertDate = (date) => {
  let NewDate = date.split('/').reverse().join('-');

  return NewDate;
};
export const convertDate2 = (date) => {
  let NewDate = dayjs(
    new Date(moment(moment(convertDate(date)))).toISOString()
  ).format(formatDisplay);

  return NewDate;
};

export const fetchDataActividadesPropietarios = async (
  actividades,
  itemSelected
) => {
  if (actividades) {
    await actividadApi
      .post(`/asociadoPropietarios`, { data: actividades })
      .then((response) => {
        if (itemSelected && itemSelected.length > 0) {
          const result = response.data.map((value) => {
            if (itemSelected.some((item) => value.id === item.id)) {
              return false;
            } else {
              return value;
            }
          });
          Promise.resolve(result.filter((item) => item));
          // return result.filter((item) => item);
        } else {
          Promise.resolve(response.data);

          // return response.data;
        }
      })
      .catch((error) => {
        console.log(error);
        Promise.reject(error);
      });
  } else {
    return null;
  }
};
