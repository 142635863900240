import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Spin,
  Radio,
} from 'antd';
import { get, save } from '../redux/actions/gastoActions';
import { save as saveNumero } from '../redux/actions/configuracionActions';

import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
import { callStatus } from '../utils/constants';
import { Notificacion } from './Notificacion';

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
const { confirm } = Modal;

const GastoEditor = ({
  dispatch,
  id,
  parentId,
  stateData,
  visible,
  onCancel,
  proveedor,
  labor,
  inversor,
  gastoTipo,
  configuracion,
  moneda,
  multiplicador,
}) => {
  const [numeroOrden, setNumeroOrden] = useState('');
  const [monedaSelected, setMonedaSelected] = useState(1);
  const [multiplicadorSelected, setMultiplicadorSelected] = useState(1);

  const [form] = Form.useForm();

  const onChangeMultplicador = ({ target: { value } }) => {
    setMultiplicadorSelected(value);
    form.setFieldsValue({ cantidad: null, superficie: null, importe: null });
  };

  const onChangeMoneda = ({ target: { value } }) => {
    setMonedaSelected(value);
    form.setFieldsValue({
      precio: null,
      dolar: value === 1 ? null : 1,
      importe: null,
    });
  };

  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // dispatch(filterProveedor({ data: [{ name: 'tipo_id', value: '2' }] }));
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   let data = cloneDeep(stateData.object.data);

  //   data['fecha'] = moment(moment(data.fecha));

  //   if (!id && configuracion.list.status === callStatus.LIST_SUCCESS) {
  //     const numero =
  //       parseInt(
  //         configuracion.list.data.find((value) => value.nombre === 'gasto_num')
  //           .valor
  //       ) + 1;

  //     setNumeroOrden(numero);

  //     data['numero'] = numero.toString().padStart(8, '0');
  //   }

  //   form.setFieldsValue(data);
  //   setInitial(data);

  //   // eslint-disable-next-line
  // }, [stateData]);

  useEffect(() => {
    if (!id && configuracion.list.status === callStatus.LIST_SUCCESS) {
      let data = {};
      data['fecha'] = moment(moment(data.fecha));

      const numero =
        parseInt(
          configuracion.list.data.find((value) => value.nombre === 'gasto_num')
            .valor
        ) + 1;

      setNumeroOrden(numero);

      data['numero'] = numero.toString().padStart(8, '0');

      data['multiplicador_id'] = 1;
      data['moneda_id'] = 1;
      form.setFieldsValue(data);
      setInitial(data);
    }

    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      let data = cloneDeep(stateData.object.data);
      data['fecha'] = moment(moment(data.fecha));

      setMultiplicadorSelected(data.multiplicador_id);
      setMonedaSelected(data.moneda_id);

      form.setFieldsValue(data);
      setInitial(data);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      Notificacion('El registro se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      Notificacion(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object, configuracion.list]);

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);
    data.actividad_id = parentId;

    dispatch(save(data)).then((response) => {
      if (!id) {
        dispatch(saveNumero({ id: 3, valor: numeroOrden }));
      }
      onCancel();
    });
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  const calcImporte = () => {
    const multiplicador_id = form.getFieldValue('multiplicador_id');

    const cantidad = form.getFieldValue('cantidad');
    const superficie = form.getFieldValue('superficie');
    const precio = form.getFieldValue('precio');
    const dolar = form.getFieldValue('dolar');

    if (multiplicador_id === 1 && cantidad && precio && dolar) {
      const importe = (cantidad * precio) / dolar;
      form.setFieldsValue({ importe: importe.toFixed(2) });
    }

    if (multiplicador_id === 2 && superficie && precio && dolar) {
      const importe = (superficie * precio) / dolar;
      form.setFieldsValue({ importe: importe.toFixed(2) });
    }
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          mask
          initialWidth={700}
          initialHeight={700}
          maskClosable={false}
          visible={visible}
          title={stateData.object.data.id ? 'Editar Gasto' : 'Nueva Gasto'}
          okText="Guardar"
          cancelText="Salir"
          onCancel={handleOnCancel}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Spin
            spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={stateData.object.data}
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="id" label="id" style={{ display: 'none' }}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 0]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item
                    name="fecha"
                    label="Fecha"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <DatePicker
                      format={formatDisplay}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item
                    name="numero"
                    label="Número"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item
                    name="tipo_id"
                    label="Tipo"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select>
                      {gastoTipo.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="multiplicador_id"
                    label=""
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Radio.Group
                      optionType="button"
                      buttonStyle="solid"
                      style={{ width: '100%' }}
                      defaultValue={multiplicadorSelected}
                    >
                      {multiplicador.list.data.map((item) => (
                        <Radio.Button
                          value={item.id}
                          onChange={onChangeMultplicador}
                          style={{ width: '50%' }}
                        >
                          {item.nombre}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={
                    multiplicadorSelected === 2
                      ? { display: 'none' }
                      : { display: 'block' }
                  }
                >
                  <Form.Item
                    name="cantidad"
                    label=""
                    rules={[
                      {
                        required: multiplicadorSelected === 1,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      addonBefore="Tn"
                      onBlur={calcImporte}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={
                    multiplicadorSelected === 1
                      ? { display: 'none' }
                      : { display: 'block' }
                  }
                >
                  <Form.Item
                    name="superficie"
                    label=""
                    rules={[
                      {
                        required: multiplicadorSelected === 2,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      addonBefore="Ha"
                      onBlur={calcImporte}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item
                    name="moneda_id"
                    label="Moneda"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Radio.Group
                      defaultValue={monedaSelected}
                      optionType="button"
                      buttonStyle="solid"
                      style={{ width: '100%' }}
                    >
                      {moneda.list.data.map((item) => (
                        <Radio.Button
                          value={item.id}
                          onChange={onChangeMoneda}
                          style={{ width: '50%' }}
                        >
                          {item.nombre}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item
                    name="precio"
                    label="Precio"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      addonBefore={monedaSelected === 1 ? '$' : 'U$D'}
                      onBlur={calcImporte}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  style={
                    monedaSelected === 2
                      ? { display: 'none' }
                      : { display: 'block' }
                  }
                >
                  <Form.Item
                    name="dolar"
                    label="Dolar"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      addonBefore="U$D"
                      onBlur={calcImporte}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item name="importe" label="Importe">
                    <Input type="number" addonBefore="U$D" readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </DraggableModal>
      </DraggableModalProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  const { gasto, inversor, gastoTipo, configuracion, moneda, multiplicador } =
    state;
  const stateData = gasto;
  return {
    stateData,
    inversor,
    gastoTipo,
    configuracion,
    moneda,
    multiplicador,
  };
};
export default connect(mapStateToProps)(GastoEditor);
