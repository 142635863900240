import { API_URL } from '../utils/global';

const axiosDef = require('axios').default;

export const defaultErrorHandler = (axiosError) => {
  let error = null;
  if (axiosError.response && axiosError.response.data) {
    error = axiosError.response.data.errors
      ? axiosError.response.data.errors
      : axiosError.response.data.message
      ? axiosError.response.data.message
      : 'Error desconocido';
  } else {
    error = axiosError.message ? axiosError.message : 'Error desconocido';
  }

  if (axiosError.response && axiosError.response.status === 401) {
    localStorage.removeItem('auth');
    if (axiosError.config.url !== '/login') {
      window.location.reload();
    }
  }

  return Promise.reject(error);
};

export const defaultSuccesHandler = (response) => {
  return Promise.resolve(response);
};

export function createApi({
  baseURL = `${API_URL}`,
  timeout = 20000,
  headers = { 'Content-Type': 'application/json' },
  withCredentials = false,
  errorHandler = defaultErrorHandler,
  successHandler = defaultSuccesHandler,
  axios = axiosDef.create(),
}) {
  axios.defaults.baseURL = baseURL;
  axios.defaults.timeout = timeout;
  axios.defaults.headers = headers;
  axios.defaults.withCredentials = withCredentials;
  axios.defaults.validateStatus = (status) => {
    return status < 300;
  };
  axios.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
  );
  axios.interceptors.request.use(
    (config) => {
      if (localStorage.auth) {
        const jwt = JSON.parse(localStorage.auth).data.access_token;
        if (jwt) {
          config.headers['Authorization'] = 'Bearer ' + jwt;
        }
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  return axios;
}

export const campanaApi = createApi({
  baseURL: `${API_URL}/campana`,
});

export const alquilerApi = createApi({
  baseURL: `${API_URL}/alquiler`,
});

export const alquilerPropietarioApi = createApi({
  baseURL: `${API_URL}/alquiler-propietario`,
});

export const contratoApi = createApi({
  baseURL: `${API_URL}/contrato`,
});

export const contratoCuotaApi = createApi({
  baseURL: `${API_URL}/contrato-cuota`,
});

export const actividadApi = createApi({
  baseURL: `${API_URL}/actividad`,
});

export const actividadInversorApi = createApi({
  baseURL: `${API_URL}/actividad-inversor`,
});

export const ordenTrabajoApi = createApi({
  baseURL: `${API_URL}/orden-trabajo`,
});

export const ordenTrabajoInsumoApi = createApi({
  baseURL: `${API_URL}/orden-trabajo-insumo`,
});

export const cultivoApi = createApi({
  baseURL: `${API_URL}/cultivo`,
});

export const insumoApi = createApi({
  baseURL: `${API_URL}/insumo`,
});

export const laborApi = createApi({
  baseURL: `${API_URL}/labor`,
});

export const inversorApi = createApi({
  baseURL: `${API_URL}/inversor`,
});

export const establecimientoApi = createApi({
  baseURL: `${API_URL}/establecimiento`,
});

export const propietarioApi = createApi({
  baseURL: `${API_URL}/propietario`,
});

export const proveedorApi = createApi({
  baseURL: `${API_URL}/proveedor`,
});

export const proveedorTipoApi = createApi({
  baseURL: `${API_URL}/proveedortipo`,
});

export const exampleApi = createApi({
  baseURL: `${API_URL}/cliente`,
});

export const condicionIvaApi = createApi({
  baseURL: `${API_URL}/condicioniva`,
});

export const authApi = createApi({
  baseURL: `${API_URL}/auth`,
});

export const configuracionApi = createApi({
  baseURL: `${API_URL}/configuracion`,
});

export const gastoApi = createApi({
  baseURL: `${API_URL}/gasto`,
});

export const gastoTipoApi = createApi({
  baseURL: `${API_URL}/gastoTipo`,
});
export const produccionRetiroApi = createApi({
  baseURL: `${API_URL}/produccion-retiro`,
});

export const produccionApi = createApi({
  baseURL: `${API_URL}/produccion`,
});
export const gananciaApi = createApi({
  baseURL: `${API_URL}/ganancia`,
});
export const cacheApi = createApi({
  baseURL: `${API_URL}/cache`,
});
