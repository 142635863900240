import { createReducer } from '../utils/DataReducer';

export const configuracionReducer = createReducer({
  dataName: 'CONFIGURACION',
});

export const condicionIvaReducer = createReducer({
  dataName: 'CONDICION_IVA',
});

export const campanaReducer = createReducer({
  dataName: 'CAMPANA',
});

export const actividadReducer = createReducer({
  dataName: 'ACTIVIDAD',
});

export const alquilerReducer = createReducer({
  dataName: 'ALQUILER',
});

export const alquilerPropietarioReducer = createReducer({
  dataName: 'ALQUILER_PROPIETARIO',
});

export const contratoReducer = createReducer({
  dataName: 'CONTRATO',
});

export const contratoCuotaReducer = createReducer({
  dataName: 'CONTRATO_CUOTA',
});

export const contratoCuotaEstadoReducer = createReducer({
  dataName: 'CONTRATO_CUOTA_ESTADO',
});

export const formaPagoContratoReducer = createReducer({
  dataName: 'FORMA_PAGO_CONTRATO',
});

export const actividadInversorReducer = createReducer({
  dataName: 'ACTIVIDAD_INVERSOR',
});

export const ordenTrabajoReducer = createReducer({
  dataName: 'ORDEN_TRABAJO',
});
export const ordenTrabajoEstadoReducer = createReducer({
  dataName: 'ORDEN_TRABAJO_ESTADO',
});

export const ordenTrabajoInsumoReducer = createReducer({
  dataName: 'ORDEN_TRABAJO_INSUMO',
});

export const cultivoReducer = createReducer({
  dataName: 'CULTIVO',
});

export const insumoReducer = createReducer({
  dataName: 'INSUMO',
});

export const laborReducer = createReducer({
  dataName: 'LABOR',
});

export const inversorReducer = createReducer({
  dataName: 'INVERSOR',
});

export const establecimientoReducer = createReducer({
  dataName: 'ESTABLECIMIENTO',
});

export const propietarioReducer = createReducer({
  dataName: 'PROPIETARIO',
});

export const proveedorReducer = createReducer({
  dataName: 'PROVEEDOR',
});

export const proveedorTipoReducer = createReducer({
  dataName: 'PROVEEDOR_TIPO',
});

export const gastoReducer = createReducer({
  dataName: 'GASTO',
});

export const gastoTipoReducer = createReducer({
  dataName: 'GASTO_TIPO',
});

export const produccionRetiroReducer = createReducer({
  dataName: 'PRODUCCION-RETIRO',
});

export const destinoReducer = createReducer({
  dataName: 'DESTINO',
});

export const produccionReducer = createReducer({
  dataName: 'PRODUCCION',
});

export const gananciaReducer = createReducer({
  dataName: 'GANANCIA',
});

export const cacheReducer = createReducer({
  dataName: 'CACHE',
});

export const monedaReducer = createReducer({
  dataName: 'MONEDA',
});

export const multiplicadorReducer = createReducer({
  dataName: 'MULTIPLICADOR',
});
