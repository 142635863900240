import { getAll as propietarios } from './actions/propietarioActions';
import { getAll as establecimientos } from './actions/establecimientoActions';
import { getAll as cultivos } from './actions/cultivoActions';
import { getAll as insumos } from './actions/insumoActions';
import { getAll as inversores } from './actions/inversorActions';
import { getAll as labores } from './actions/laborActions';
import { getAll as ordenTrabajoEstados } from './actions/ordenTrabajoEstadoActions';
import { getAll as contratoCuotaEstados } from './actions/contratoCuotaEstadoActions';
import { getAll as proveedores } from './actions/proveedorActions';
import { getAll as formaPagoContrato } from './actions/contratoFormaPagoActions';
import { getAll as condicionIva } from './actions/condicionIvaActions';
import { getAll as configuracion } from './actions/configuracionActions';
import { getAll as proveedorTipo } from './actions/proveedorTipoActions';
import { getAll as gastoTipo } from './actions/gastoTipoActions';
import { getAll as destino } from './actions/destinoActions';
import {
  createListDataFailed,
  createListDataInProcess,
  createListDataSuccess,
} from '../utils/DataAction';
import { cacheApi } from '../utils/api';

export const doGetCache = async (dispatch) => {
  await propietarios(dispatch);
  await proveedores(dispatch);
  await cultivos(dispatch);
  await establecimientos(dispatch);
  await insumos(dispatch);
  await labores(dispatch);
  await inversores(dispatch);
  await ordenTrabajoEstados(dispatch);
  await contratoCuotaEstados(dispatch);
  await formaPagoContrato(dispatch);
  await condicionIva(dispatch);
  await configuracion(dispatch);
  await proveedorTipo(dispatch);
  await gastoTipo(dispatch);
  await destino(dispatch);
};

export const getAll = () => {
  return (dispatch) => {
    dispatch(createListDataInProcess('CACHE')());
    return cacheApi
      .get()
      .then((response) => {
        dispatch(createListDataSuccess('CACHE')(response.data));
        dispatch(createListDataSuccess('MONEDA')(response.data.moneda));
        dispatch(
          createListDataSuccess('MULTIPLICADOR')(response.data.multiplicador)
        );

        dispatch(
          createListDataSuccess('PROPIETARIO')(response.data.propietarios)
        );
        dispatch(createListDataSuccess('PROVEEDOR')(response.data.proveedores));
        dispatch(createListDataSuccess('CULTIVO')(response.data.cultivos));
        dispatch(
          createListDataSuccess('ESTABLECIMIENTO')(
            response.data.establecimientos
          )
        );
        dispatch(createListDataSuccess('INSUMO')(response.data.insumos));
        dispatch(createListDataSuccess('LABOR')(response.data.labores));
        dispatch(createListDataSuccess('INVERSOR')(response.data.inversores));
        dispatch(
          createListDataSuccess('ORDEN_TRABAJO_ESTADO')(
            response.data.ordenTrabajoEstados
          )
        );
        dispatch(
          createListDataSuccess('CONTRATO_CUOTA_ESTADO')(
            response.data.contratoCuotaEstados
          )
        );
        dispatch(
          createListDataSuccess('FORMA_PAGO_CONTRATO')(
            response.data.formaPagoContrato
          )
        );
        dispatch(
          createListDataSuccess('CONDICION_IVA')(response.data.condicionIva)
        );
        dispatch(
          createListDataSuccess('CONFIGURACION')(response.data.configuracion)
        );
        dispatch(
          createListDataSuccess('PROVEEDOR_TIPO')(response.data.proveedorTipo)
        );
        dispatch(createListDataSuccess('GASTO_TIPO')(response.data.gastoTipo));
        dispatch(createListDataSuccess('DESTINO')(response.data.destino));
      })
      .catch((error) => {
        dispatch(createListDataFailed('CACHE')(error));
      });
  };
};
