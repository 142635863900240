import React, { useState, useEffect } from 'react';
import ActividadInversorEditor from './ActividadInversorEditor';
import { connect } from 'react-redux';
import {
  getAllByParent,
  setPending,
  deleted,
} from '../redux/actions/actividadInversorActions';
import { get as getParent } from '../redux/actions/actividadActions';
import { callStatus } from '../utils/constants';

import { Button, Layout, Modal, Space } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  EditFilled,
  DeleteFilled,
} from '@ant-design/icons';
import styled from 'styled-components';
import MetaTable from './base/MetaTable';

const { confirm } = Modal;

const { Content, Header, Footer } = Layout;

const SelectedContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

const ActividadInversorList = ({
  location,
  dispatch,
  stateData,
  match: {
    params: { id, id2 },
  },
  parent,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [rowEdit, setRowEdit] = useState(null);

  useEffect(() => {
    dispatch(getAllByParent(id2));
    dispatch(getParent(id2));

    // eslint-disable-next-line
  }, []);

  const handleEdit = (data) => {
    setRowEdit(data);
    setShowEdit(true);
  };

  const handleDelete = (data) => {
    dispatch(deleted(data.id));
  };

  const handleConfirm = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  const handleHideEditor = () => {
    setShowEdit(false);
    setRowEdit(null);
    dispatch(setPending());
  };

  const columns = [
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={(e) => handleEdit(record)} />
          <DeleteFilled onClick={(e) => handleConfirm(record)} />
        </Space>
      ),
      width: 60,
    },
    {
      width: 300,
      ellipsis: true,
      title: 'Nombre',
      dataIndex: 'nombre',
    },
  ];

  return (
    <Layout style={{ height: '100%' }}>
      <Header
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginTop: '5px',
        }}
      >
        {parent.object.status === callStatus.OBJECT_SUCCESS && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <div
              style={{
                height: '17px',
              }}
            >
              <b>Campaña: </b>
              {parent.object.data.campana.nombre}
            </div>

            <div
              style={{
                height: '17px',
              }}
            >
              <b>Actividad: </b>
              {parent.object.data.establecimiento.nombre}/
              {parent.object.data.cultivo.nombre}
            </div>
          </div>
        )}
      </Header>
      <Content>
        <MetaTable
          dataSource={stateData.list.data}
          dataColumns={columns}
          bordered={false}
          selection={false}
          loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
        />
      </Content>
      <Footer>
        <FooterContainer>
          <SelectedContainer></SelectedContainer>
          <ActionsContainer>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={(e) => handleEdit({})}
            >
              Agregar
            </Button>
          </ActionsContainer>
        </FooterContainer>
      </Footer>
      {showEdit ? (
        <ActividadInversorEditor
          id={rowEdit.id}
          parentId={id2}
          onCancel={handleHideEditor}
          // onCreate={handleSaveEditor}
          visible={true}
        />
      ) : (
        false
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { actividadInversor, actividad } = state;
  const stateData = actividadInversor;
  const parent = actividad;
  return {
    stateData,
    parent,
  };
};
export default connect(mapStateToProps)(withRouter(ActividadInversorList));
