import React, { useEffect } from 'react';
import Navbar from './Navbar';
import { connect } from 'react-redux';
import { callStatus } from './utils/constants';
import Login from './Login';
import { getAll } from './redux/CacheActions';
import { Spin, Layout, Button } from 'antd';
import { doLogout } from './redux/AuthActions';
const { Content } = Layout;

const App = ({ dispatch, auth, cache }) => {
  useEffect(() => {
    if (auth.status === callStatus.SUCCESS) {
      dispatch(getAll());
    }
    // eslint-disable-next-line
  }, [auth]);

  if (auth.status !== callStatus.SUCCESS) {
    return (
      <>
        <Login />
      </>
    );
  } else {
    if (cache.list.status === callStatus.LIST_SUCCESS) {
      return <Navbar />;
    } else if (cache.list.status === callStatus.LIST_FAILED) {
      return (
        <div style={{ height: '100vh' }}>
          <Layout style={{ height: '100%' }}>
            <Content>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  Ha ocurrido un error con al carga de la configuración,
                  recargue la aplicación o vuelva a iniciar sesión.
                </div>
                <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                  Si el problema persiste contactar con el administrador
                </div>

                <div>
                  <Button onClick={() => dispatch(getAll())}>Recargar</Button>
                  <Button
                    onClick={() => doLogout(dispatch)}
                    style={{ marginLeft: '10px' }}
                  >
                    Iniciar sesión
                  </Button>
                </div>
              </div>
            </Content>
          </Layout>
        </div>
      );
    } else {
      return (
        <div style={{ height: '100vh' }}>
          <Layout style={{ height: '100%' }}>
            <Content>
              <Spin style={{ height: '100vh' }} tip="Cargando configuración">
                <Layout style={{ height: '100%' }}></Layout>
              </Spin>
            </Content>
          </Layout>
        </div>
      );
    }
  }
};

const mapStateToProps = (state) => {
  const {
    auth,
    proveedor,
    propietario,
    establecimiento,
    cultivo,
    insumo,
    inversor,
    labor,
    ordenTrabajoEstado,
    contratoCuotaEstado,
    formaPagoContrato,
    condicionIva,
    proveedorTipo,
    gastoTipo,
    destino,
    configuracion,
    moneda,
    multiplicador,
    cache,
  } = state;

  return {
    auth,
    proveedor,
    propietario,
    establecimiento,
    cultivo,
    insumo,
    inversor,
    labor,
    ordenTrabajoEstado,
    contratoCuotaEstado,
    formaPagoContrato,
    condicionIva,
    proveedorTipo,
    gastoTipo,
    destino,
    configuracion,
    moneda,
    multiplicador,
    cache,
  };
};
export default connect(mapStateToProps)(App);
