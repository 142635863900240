import { configuracionApi } from '../../utils/api';
import {
  getObjectDataAction,
  createObjectDataPending,
  getListDataAction,
  saveObjectDataAction,
  createListDataDeletedPending,
} from '../../utils/DataAction';

export const get = (id) =>
  getObjectDataAction({
    dataName: 'CONFIGURACION',
    api: configuracionApi,
    relUrl: id,
  });

export const getAll = getListDataAction({
  dataName: 'CONFIGURACION',
  api: configuracionApi,
  relUrl: '',
});

export const save = (data) =>
  saveObjectDataAction({
    dataName: 'CONFIGURACION',
    api: configuracionApi,
    relUrl: '',
    data: data,
  });

export const setPending = createObjectDataPending('CONFIGURACION');
export const setDeletedPending = createListDataDeletedPending('CONFIGURACION');
