import { actividadInversorApi } from '../../utils/api';
import {
  getObjectDataAction,
  createObjectDataPending,
  getListDataAction,
  saveObjectDataAction,
  deleteObjectDataAction,
  getListDeletedDataAction,
  createListDataDeletedPending,
  restoreObjectDataAction,
  getListByParentDataAction,
} from '../../utils/DataAction';

export const get = (id) =>
  getObjectDataAction({
    dataName: 'ACTIVIDAD_INVERSOR',
    api: actividadInversorApi,
    relUrl: id,
  });

export const getAllByParent = (id) =>
  getListByParentDataAction({
    dataName: 'ACTIVIDAD_INVERSOR',
    api: actividadInversorApi,
    relUrl: `indexByParent/${id}`,
  });

export const getAll = getListDataAction({
  dataName: 'ACTIVIDAD_INVERSOR',
  api: actividadInversorApi,
  relUrl: '',
});

export const save = (data) =>
  saveObjectDataAction({
    dataName: 'ACTIVIDAD_INVERSOR',
    api: actividadInversorApi,
    relUrl: '',
    data: data,
  });

export const deleted = (id) =>
  deleteObjectDataAction({
    dataName: 'ACTIVIDAD_INVERSOR',
    api: actividadInversorApi,
    relUrl: id,
  });

export const getAllDeleted = getListDeletedDataAction({
  dataName: 'ACTIVIDAD_INVERSOR',
  api: actividadInversorApi,
  relUrl: 'restore',
});

export const restore = (data) =>
  restoreObjectDataAction({
    dataName: 'ACTIVIDAD_INVERSOR',
    api: actividadInversorApi,
    relUrl: 'restore',
    data: data,
  });

export const setPending = createObjectDataPending('ACTIVIDAD_INVERSOR');
export const setDeletedPending =
  createListDataDeletedPending('ACTIVIDAD_INVERSOR');
